import {pxToRem} from "@/styles/helpers";
import styled from "styled-components";

const Container = styled.div`
  max-width: 1614px;
  margin: auto;
  padding-left: ${pxToRem(20)};
  padding-right: ${pxToRem(20)};
  padding-bottom: ${pxToRem(32)};
`;

export default Container;
