import React from "react";
import {
  Content,
  InfoCard,
  InfoDescription,
  InfoValue,
  InfoValueIcon,
  Wrapper,
} from "./localizationInfo.style";
import {useTranslation} from "react-i18next";

interface LocalizationInfoProps {
  door: boolean;
  fans: boolean;
  smoke: boolean;
  earth: boolean;
  grid: number;
  pv: boolean;
}

const LocalizationInfo = ({
  door,
  fans,
  smoke,
  earth,
  grid,
  pv,
}: LocalizationInfoProps): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <InfoCard>
        <Content>
          <InfoDescription>
            {t("components.cabinetStatus.door")}
          </InfoDescription>
          <InfoValue positive={door}>
            <InfoValueIcon positive={door} />
            {door ? t("shared.closed") : t("shared.open")}
          </InfoValue>
        </Content>
      </InfoCard>

      <InfoCard>
        <Content>
          <InfoDescription>
            {" "}
            {t("components.cabinetStatus.fans")}
          </InfoDescription>
          <InfoValue positive={fans}>
            <InfoValueIcon positive={fans} />
            {fans ? t("shared.working") : t("shared.notWorking")}
          </InfoValue>
        </Content>
      </InfoCard>
      <InfoCard>
        <Content>
          <InfoDescription>
            {t("components.cabinetStatus.grid")}
          </InfoDescription>
          <InfoValue positive={grid > 0}>
            <InfoValueIcon positive={grid > 0} />
            {grid} {t("shared.kw")}
          </InfoValue>
        </Content>
      </InfoCard>
      <InfoCard>
        <Content>
          <InfoDescription>{t("components.cabinetStatus.pv")}</InfoDescription>
          <InfoValue positive={pv}>
            <InfoValueIcon positive={pv} />
            {pv ? t("shared.working") : t("shared.notWorking")}
          </InfoValue>
        </Content>
      </InfoCard>
      <InfoCard>
        <Content>
          <InfoDescription>
            {t("components.cabinetStatus.smokeDet")}
          </InfoDescription>
          <InfoValue positive={smoke}>
            <InfoValueIcon positive={smoke} />
            {smoke ? t("shared.working") : t("shared.notWorking")}
          </InfoValue>
        </Content>
      </InfoCard>
      <InfoCard>
        <Content>
          <InfoDescription>
            {t("components.cabinetStatus.earthFault")}
          </InfoDescription>
          <InfoValue positive={earth}>
            <InfoValueIcon positive={earth} />
            {earth ? t("shared.working") : t("shared.notWorking")}
          </InfoValue>
        </Content>
      </InfoCard>
    </Wrapper>
  );
};

export default LocalizationInfo;
