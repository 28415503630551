import React, {useContext} from "react";
import {
  ConsumptionCard,
  ConsumptionCardContent,
  ConsumptionDescription,
  ConsumptionIconWrapper,
  ConsumptionTitle,
  Content,
  TopContent,
} from "./dashboard.style";
import Layout from "@/components/layout/layout";
import EmissionByYear from "@/components/emissionByYear/emissionByYear";
import {ProjectContext} from "@/contexts/projectContext";
import EmissionActivity from "@/components/emissionActivity/emissionActivity";
import ScopeBox from "@/components/scopeBox/scopeBox";
import EmissionByMonth from "@/components/emissionByMonth/emissionByMonth";
import {useTranslation} from "react-i18next";
import BatteryCardHorizontal from "@/components/batteryCardHorizontal/batteryCardHorizontal";
import GabinetStatus from "@/components/cabinetStatus/cabinetStatus";

const Dashboard = (): JSX.Element => {
  const {project} = useContext(ProjectContext);
  const {t} = useTranslation();

  return (
    <Layout home={false} active="dashboard">
      <>
        <h1>{t("pages.dashboard.title")}</h1>
        <TopContent>
          <ConsumptionCard>
            <ConsumptionCardContent>
              <ConsumptionTitle>
                {t("pages.dashboard.currentConsumption")}
              </ConsumptionTitle>
              <ConsumptionDescription>
                <ConsumptionIconWrapper>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none">
                    <path
                      d="M5.33341 16L7.21341 14.12L14.6667 21.56V5.33337H17.3334V21.56L24.7734 14.1067L26.6667 16L16.0001 26.6667L5.33341 16Z"
                      fill="#D2E6AE"
                    />
                  </svg>
                </ConsumptionIconWrapper>
                {project.currentConsumption} {t("shared.kw")}
              </ConsumptionDescription>
            </ConsumptionCardContent>
          </ConsumptionCard>
          <BatteryCardHorizontal {...project.battery} />
          <GabinetStatus {...project.info} />
        </TopContent>
        <Content>
          <EmissionByYear
            values={project.energyConsumptionByYear
              .map((yearEmission, key) => {
                return {
                  year: (2023 - key).toString(),
                  emission: yearEmission.reduce((sum, monthEmission) => {
                    return monthEmission.tco2 + sum;
                  }, 0),
                };
              })
              .reverse()}
          />
          <EmissionByMonth
            values={project.energyConsumptionByYear
              .map((yearEmission, key) => {
                return {
                  year: (2023 - key).toString(),
                  emission: yearEmission,
                };
              })
              .reverse()}
          />
          <EmissionActivity />
          <ScopeBox title={t("pages.dashboard.scope1")} {...project.scope1} />
          <ScopeBox title={t("pages.dashboard.scope2")} {...project.scope2} />
          <ScopeBox title={t("pages.dashboard.scope3")} {...project.scope3} />
        </Content>
      </>
    </Layout>
  );
};

export default Dashboard;
