import {LinkWithQuery} from "@/components/linkWithQuery/linkWithQuery";
import Card, {CardContent} from "@/components/ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: ${pxToRem(16)};
  column-gap: ${pxToRem(16)};

  ${theme.breakpoints.down("lg")} {
    grid-template-columns: 1fr;
  }
  align-items: baseline;
`;

export const GeneralCard = styled(Card)``;

export const NewtorkCard = styled(Card)``;

export const ServiceCard = styled(Card)``;

export const SettingsCardContent = styled(CardContent)`
  padding-left: ${pxToRem(24)};
  padding-right: ${pxToRem(24)};
  padding-top: ${pxToRem(32)};
  padding-bottom: ${pxToRem(24)};
`;

export const SettingsCardTitle = styled.h2`
  margin-bottom: ${pxToRem(24)};
`;

export const SettingsItem = styled.div`
  padding-top: ${pxToRem(10)};
  padding-bottom: ${pxToRem(10)};
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  border-bottom: 1px solid var(--Grey-100, #efefef);
`;

export const SettingsTitle = styled.span`
  color: ${theme.colors.grey[500]};
`;

export const SettingsValue = styled.span`
  color: ${theme.colors.main};
  font-weight: 700;
`;

export const NetworkSettingsContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${pxToRem(64)};
`;

export const CheckboxWrapper = styled.div`
  padding-top: ${pxToRem(18)};
`;

export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
`;

export const DetailsLink = styled(LinkWithQuery)`
  text-align: right;
  color: ${theme.colors.main};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-items: end;
  font-size: ${fluidSize(12, 14)};
  justify-self: flex-end;
`;

export const DetailsText = styled.span`
  text-align: right;
  color: ${theme.colors.main};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-items: end;
  font-size: ${fluidSize(12, 14)};
  justify-self: flex-end;
`;
