import React from "react";
import {
  ContentWrapper,
  ItemTitle,
  ItemValue,
  NormLabel,
  NormLabelIcon,
  Wrapper,
} from "./converterBox.style";
import {useTranslation} from "react-i18next";

interface Props {
  vdc: number;
  pdc: number;
  idc: number;
  vdcl: number;
  tin: number;
  tout: number;
  vac: number;
  pac: number;
  iac: number;
  sac: number;
  freq: number;
  copt: number;
  status: boolean;
  tp1: string;
  tp2: string;
  fdir: string;
}

const ConverterBox = ({
  vdc,
  pdc,
  idc,
  vdcl,
  tin,
  tout,
  vac,
  pac,
  iac,
  sac,
  freq,
  copt,
  status,
  tp1,
  tp2,
  fdir,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <ContentWrapper>
        <ItemTitle>{t("components.converterBox.vdc")}</ItemTitle>
        <ItemValue>{vdc.toFixed(2)}</ItemValue>
        <ItemTitle>{t("components.converterBox.pdc")}</ItemTitle>
        <ItemValue>{pdc.toFixed(1)}</ItemValue>
        <ItemTitle>{t("components.converterBox.idc")}</ItemTitle>
        <ItemValue>{idc.toFixed(1)}</ItemValue>
        <ItemTitle>{t("components.converterBox.vdcl")}</ItemTitle>
        <ItemValue>{vdcl.toFixed(1)}</ItemValue>
        <ItemTitle>{t("components.converterBox.tin")}</ItemTitle>
        <ItemValue>{tin.toFixed(1)}</ItemValue>
        <ItemTitle>{t("components.converterBox.tout")}</ItemTitle>
        <ItemValue>{tout.toFixed(1)}</ItemValue>
      </ContentWrapper>
      <ContentWrapper>
        <ItemTitle>{t("components.converterBox.vac")}</ItemTitle>
        <ItemValue>{vac.toFixed(2)}</ItemValue>
        <ItemTitle>{t("components.converterBox.pac")}</ItemTitle>
        <ItemValue>{pac.toFixed(1)}</ItemValue>
        <ItemTitle>{t("components.converterBox.iac")}</ItemTitle>
        <ItemValue>{iac.toFixed(1)}</ItemValue>
        <ItemTitle>{t("components.converterBox.sac")}</ItemTitle>
        <ItemValue>{sac.toFixed(1)}</ItemValue>
        <ItemTitle>{t("components.converterBox.freq")}</ItemTitle>
        <ItemValue>{freq.toFixed(1)}</ItemValue>
        <ItemTitle>{t("components.converterBox.copt")}</ItemTitle>
        <ItemValue>{copt.toFixed(1)}</ItemValue>
      </ContentWrapper>
      <ContentWrapper>
        <ItemTitle>{t("components.converterBox.status")}</ItemTitle>
        <ItemValue></ItemValue>
        <NormLabel positive={status}>
          <NormLabelIcon positive={status} />
          {status
            ? t("components.converterBox.norm")
            : t("components.converterBox.notnorm")}
        </NormLabel>
        <ItemTitle>{t("components.converterBox.tp1")}</ItemTitle>
        <ItemValue>{tp1}</ItemValue>
        <ItemTitle>{t("components.converterBox.tp2")}</ItemTitle>
        <ItemValue>{tp2}</ItemValue>
        <ItemTitle>{t("components.converterBox.fdir")}</ItemTitle>
        <ItemValue>{fdir}</ItemValue>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ConverterBox;
