import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled, {css} from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

interface ArrowIconProps {
  open: boolean;
}

export const ArrowIcon = styled.svg<ArrowIconProps>`
  ${({open}) =>
    !open &&
    css`
      transform: rotate(180deg);
    `}
`;

interface OpenButtonProps {
  type: "glass" | "grey";
  open: boolean;
}

export const OpenButton = styled.button<OpenButtonProps>`
  width: 100%;
  border-radius: ${pxToRem(8)};
  padding: ${pxToRem(12)};
  padding-top: ${pxToRem(8)};
  padding-bottom: ${pxToRem(8)};
  font-size: ${fluidSize(14, 16)};
  font-weight: 700;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: ${theme.colors.white};
    color: ${theme.colors.main};
    border: 1px solid ${theme.colors.white};
  }

  ${({type, open}) =>
    type === "grey" &&
    css`
      color: ${open ? theme.colors.main : theme.colors.grey[500]};
      background-color: ${open ? theme.colors.white : "transparent"};

      border: 1px solid ${open ? theme.colors.white : theme.colors.grey[200]};
    `}
  ${({type, open}) =>
    type === "glass" &&
    css`
      color: ${open ? theme.colors.main : theme.colors.white};
      background-color: ${open ? theme.colors.white : "transparent"};

      border: 1px solid ${open ? theme.colors.white : theme.colors.grey[200]};
    `}
`;

interface OptionsWrapperProps {
  open: boolean;
  orientation: "top" | "bottom";
}

export const OptionsWrapper = styled.div<OptionsWrapperProps>`
  min-width: 100%;
  position: absolute;
  ${({orientation}) =>
    orientation === "bottom" &&
    css`
      top: 0px;
      transform: translateY(35%);
    `}
  ${({orientation}) =>
    orientation === "top" &&
    css`
      bottom: 0px;
      transform: translateY(-50%);
    `}
  
  display: ${props => (props.open ? "flex" : "none")};
  flex-direction: column;
`;

export const Option = styled.button`
  background: ${theme.colors.white};
  backdrop-filter: blur(12px);
  padding: ${pxToRem(12)};
  color: ${theme.colors.grey[400]};
  font-size: ${fluidSize(12, 14)};
  border: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid ${theme.colors.grey[200]};
  white-space: nowrap;
  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
    border-bottom: 0;
  }
  &:hover {
    > div {
      background-color: ${theme.colors.lightGreen[400]};
    }
  }
`;

interface OptionIconWrapperProps {
  selected: boolean;
}

export const OptionIconWrapper = styled.div<OptionIconWrapperProps>`
  width: 14px;
  height: 14px;
  background-color: ${props =>
    props.selected ? theme.colors.lightGreen[400] : theme.colors.grey[100]};
  margin-left: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><path d="M3.66462 6.61439L1.92712 4.87689L1.33545 5.46439L3.66462 7.79356L8.66462 2.79355L8.07712 2.20605L3.66462 6.61439Z" fill="%23D2E6AE" /></svg>');
  }
`;
