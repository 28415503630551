import HarmonyPlaza from "./harmonyplaza";
import EternalSkyTower from "./eternalskytower";
import EmeraldHavenTower from "./emeraldhaventower";

const localizationsData = [HarmonyPlaza, EternalSkyTower, EmeraldHavenTower];
export default localizationsData;

export interface Client {
  name: string;
  image?: string;
  projects: Array<typeof HarmonyPlaza>;
}

export const defaultClient: Client = {
  name: "Test",
  projects: localizationsData,
};
