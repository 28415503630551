import React, {useEffect, useState} from "react";
import {
  BackToHomeLink,
  Content,
  LocalizationDropdownContent,
  LocalizationDropdownWrapper,
  MenuIcon,
  MenuItem,
  MenuItemsWrapper,
  MenuText,
  SystemCheckedText,
  SystemStatusDescription,
  SystemStatusIconWrapper,
  Wrapper,
} from "./menu.style";
import LocalizationDropdown from "../localizationDropdown/localizationDropdown";
import {useTranslation} from "react-i18next";

interface Props {
  variant?: "grey" | "glass";
  goBack?: boolean;
  active?: "dashboard" | "data" | "settings";
}

const Menu = ({
  variant = "grey",
  goBack = false,
  active,
}: Props): JSX.Element => {
  const {t} = useTranslation();
  const scrollThreshold = 50;
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = (): void => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, {passive: true});

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Wrapper variant={variant} transparent={scrollPosition < scrollThreshold}>
      <Content>
        {!goBack && (
          <SystemStatusDescription>
            <SystemStatusIconWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none">
                <path
                  d="M5.86339 10.5834L3.08339 7.80336L2.13672 8.74336L5.86339 12.47L13.8634 4.47003L12.9234 3.53003L5.86339 10.5834Z"
                  fill="#D2E6AE"
                />
              </svg>
            </SystemStatusIconWrapper>
            <SystemCheckedText>
              {t("components.menu.systemsWorking")}
            </SystemCheckedText>
          </SystemStatusDescription>
        )}
        {goBack && (
          <BackToHomeLink to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none">
              <path
                d="M14.2951 18L15.7051 16.59L11.1251 12L15.7051 7.41L14.2951 6L8.29508 12L14.2951 18Z"
                fill="#009"
              />
            </svg>
            {t("components.menu.back")}
          </BackToHomeLink>
        )}
        <MenuItemsWrapper>
          <MenuItem active={active === "dashboard"} to="/dashboard">
            <MenuIcon
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none">
              <g>
                <path
                  d="M5.33325 10.6667H10.6666V5.33337H5.33325V10.6667ZM13.3333 26.6667H18.6666V21.3334H13.3333V26.6667ZM5.33325 26.6667H10.6666V21.3334H5.33325V26.6667ZM5.33325 18.6667H10.6666V13.3334H5.33325V18.6667ZM13.3333 18.6667H18.6666V13.3334H13.3333V18.6667ZM21.3333 5.33337V10.6667H26.6666V5.33337H21.3333ZM13.3333 10.6667H18.6666V5.33337H13.3333V10.6667ZM21.3333 18.6667H26.6666V13.3334H21.3333V18.6667ZM21.3333 26.6667H26.6666V21.3334H21.3333V26.6667Z"
                  fill="currentColor"
                />
              </g>
            </MenuIcon>
            <MenuText>{t("components.menu.dashboard")}</MenuText>
          </MenuItem>
          <MenuItem to="/data" active={active === "data"}>
            <MenuIcon
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none">
              <g>
                <path
                  d="M10.6666 12H5.33325V26.6667H10.6666V12Z"
                  fill="currentColor"
                />
                <path
                  d="M26.6666 17.3334H21.3333V26.6667H26.6666V17.3334Z"
                  fill="currentColor"
                />
                <path
                  d="M18.6666 5.33337H13.3333V26.6667H18.6666V5.33337Z"
                  fill="currentColor"
                />
              </g>
            </MenuIcon>
            <MenuText>{t("components.menu.bess")}</MenuText>
          </MenuItem>
          <MenuItem to="/settings" active={active === "settings"}>
            <MenuIcon
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none">
              <g>
                <path
                  d="M25.9134 17.32L25.9001 17.3466C25.9534 16.9066 26.0067 16.4533 26.0067 16C26.0067 15.5466 25.9667 15.12 25.9134 14.68L25.9267 14.7066L29.1801 12.1466L25.9401 6.51996L22.1134 8.06663L22.1267 8.07996C21.4334 7.54663 20.6734 7.09329 19.8467 6.74663H19.8601L19.2467 2.66663H12.7534L12.1667 6.75996H12.1801C11.3534 7.10663 10.5934 7.55996 9.90007 8.09329L9.9134 8.07996L6.0734 6.51996L2.82007 12.1466L6.0734 14.7066L6.08674 14.68C6.0334 15.12 5.9934 15.5466 5.9934 16C5.9934 16.4533 6.0334 16.9066 6.10007 17.3466L6.08674 17.32L3.28674 19.52L2.84674 19.8666L6.08674 25.4666L9.92673 23.9333L9.90007 23.88C10.6067 24.4266 11.3667 24.88 12.2067 25.2266H12.1667L12.7667 29.3333H19.2334C19.2334 29.3333 19.2734 29.0933 19.3134 28.7733L19.8201 25.24H19.8067C20.6334 24.8933 21.4067 24.44 22.1134 23.8933L22.0867 23.9466L25.9267 25.48L29.1667 19.88C29.1667 19.88 28.9801 19.72 28.7267 19.5333L25.9134 17.32ZM15.9934 20.6666C13.4201 20.6666 11.3267 18.5733 11.3267 16C11.3267 13.4266 13.4201 11.3333 15.9934 11.3333C18.5667 11.3333 20.6601 13.4266 20.6601 16C20.6601 18.5733 18.5667 20.6666 15.9934 20.6666Z"
                  fill="currentColor"
                />
              </g>
            </MenuIcon>
            <MenuText>{t("components.menu.settings")}</MenuText>
          </MenuItem>
        </MenuItemsWrapper>
        <LocalizationDropdownWrapper>
          <LocalizationDropdownContent>
            <LocalizationDropdown type={variant} />
          </LocalizationDropdownContent>
        </LocalizationDropdownWrapper>
      </Content>
    </Wrapper>
  );
};

export default Menu;
