import styled from "styled-components";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import {
  CardContent,
  CardHeader,
  CardSubTitle,
  CardTitle,
} from "@/components/ui/card";
import {LinkWithQuery} from "@/components/linkWithQuery/linkWithQuery";

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  ${({theme}) => theme.breakpoints.down("xl")} {
    grid-template-columns: 1fr;
  }
  /* align-items: end; */
  min-height: calc(100vh - 96px - 81px);
  padding-top: ${pxToRem(48)};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  ${theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
  gap: ${pxToRem(16)};
  margin-top: ${pxToRem(16)};
`;

export const Column = styled.div`
  ${({theme}) => theme.breakpoints.down("xl")} {
    order: 1;
  }
`;

export const InfoColumn = styled.div`
  color: ${theme.colors.white};
  text-align: right;
`;

export const LocalizationName = styled.h1`
  font-size: ${fluidSize(32, 40)};
  font-weight: 400;
  margin-top: ${pxToRem(8)};
  margin-bottom: ${pxToRem(84)};
  color: ${theme.colors.white};
`;

export const Temperature = styled.span`
  font-size: ${fluidSize(44, 56)};
  font-weight: 700;
  margin: 0;
  color: ${theme.colors.white};
`;

export const TemperatureIcon = styled.svg`
  margin-right: ${pxToRem(16)};
  color: ${theme.colors.white};
`;

export const WeatherDescription = styled.p`
  margin: 0;
  font-size: ${fluidSize(18, 20)};
  color: ${theme.colors.grey[100]};
`;

export const ConsumptionDescription = styled.p`
  font-size: ${fluidSize(24, 28)};
  color: ${theme.colors.grey[100]};
  margin: 0;
  margin-bottom: ${pxToRem(8)};
`;

export const Consumption = styled.p`
  font-size: ${fluidSize(58, 88)};
  font-weight: 700;
  color: ${theme.colors.lightGreen[400]};
  display: inline-flex;
  align-items: center;
  margin: 0;
`;

export const ConsumptionIconWrapper = styled.div`
  display: inline-flex;
  background-color: ${theme.colors.lightGreen[400]};
  border-radius: 50%;
  width: ${pxToRem(64)};
  height: ${pxToRem(64)};
  align-items: center;
  justify-content: center;
  margin-right: ${pxToRem(24)};
`;

export const EmissionCardHeader = styled(CardHeader)`
  padding: ${pxToRem(24)};
`;

export const EmmisionCardContent = styled(CardContent)`
  padding-left: 0;
`;

export const EmissionMore = styled(LinkWithQuery)`
  color: ${theme.colors.link};
  font-size: ${fluidSize(12, 14)};
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: end;
  flex: 1;
  text-decoration: none;
`;

export const EmmisionChartWrapper = styled.div`
  width: 100%;
  height: 280px;
`;

export const LocalCardTitle = styled(CardTitle)`
  font-size: ${fluidSize(18, 20)};
  font-weight: 400;
  color: ${theme.colors.black};
`;

export const LocalCardSubTitle = styled(CardSubTitle)`
  font-size: ${fluidSize(14, 16)};
  font-weight: 400;
  color: ${theme.colors.black};
`;
