import {Link, useLocation} from "react-router-dom";
import React from "react";

export const LinkWithQuery = ({children, to, ...props}: any): JSX.Element => {
  const {search} = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};
