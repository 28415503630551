import React, {useContext, useEffect, useState} from "react";
import {
  Column,
  Consumption,
  ConsumptionDescription,
  ConsumptionIconWrapper,
  Content,
  EmissionCardHeader,
  EmissionMore,
  EmmisionCardContent,
  EmmisionChartWrapper,
  InfoColumn,
  LocalCardSubTitle,
  LocalCardTitle,
  LocalizationName,
  Row,
  Temperature,
  TemperatureIcon,
  WeatherDescription,
} from "./index.style";
import {CardGlass} from "@/components/ui/card";
import Layout from "@/components/layout/layout";
import EmissionChart from "@/components/charts/emissionChart/emissionChart";
import type {ChartData} from "@/components/charts/emissionChart/emissionChart";
import LocalizationInfo from "@/components/localizationInfo/localizationInfo";
import {ProjectContext} from "@/contexts/projectContext";
import BatteryCard from "@/components/batteryCard/batteryCard";
import {useTranslation, Trans} from "react-i18next";

const Index = (): JSX.Element => {
  const {project: localization, client} = useContext(ProjectContext);
  const [emissionChartData, setEmissionChartData] = useState<ChartData[]>([]);
  const {t} = useTranslation();

  useEffect(() => {
    const newData = localization.energyConsumption.map((consumption, key) => {
      return {
        name: (key + 1).toString(),
        ...consumption,
      };
    });
    setEmissionChartData(newData);
  }, [localization]);

  const getIcon = (type: string): JSX.Element => {
    switch (type) {
      case "sunny": {
        return (
          <>
            <path
              d="M7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C17 10.6739 16.4732 9.40215 15.5355 8.46447C14.5979 7.52678 13.3261 7 12 7C10.6739 7 9.40215 7.52678 8.46447 8.46447C7.52678 9.40215 7 10.6739 7 12Z"
              fill="currentColor"
            />
            <path
              d="M11 2H13V5H11V2ZM16.242 6.344L18.364 4.222L19.778 5.636L17.656 7.758L16.242 6.344ZM19 11H22V13H19V11ZM16.242 17.656L17.656 16.242L19.778 18.364L18.364 19.778L16.242 17.656ZM11 19H13V22H11V19ZM4.222 18.364L6.344 16.242L7.758 17.657L5.636 19.778L4.222 18.364ZM2 11H5V13H2V11ZM4.222 5.636L5.636 4.222L7.758 6.344L6.344 7.758L4.222 5.636Z"
              fill="currentColor"
            />
          </>
        );
      }
      case "rainy": {
        return (
          <>
            <path
              d="M5.376 20.876L7.376 17.876L8.624 18.708L6.624 21.708L5.376 20.876ZM9.375 20.876L11.375 17.876L12.624 18.708L10.624 21.708L9.375 20.876ZM13.376 20.876L15.376 17.876L16.625 18.708L14.625 21.708L13.376 20.876ZM15 2.29197C13.7987 2.28797 12.6168 2.59494 11.5692 3.18302C10.5217 3.77111 9.64415 4.62029 9.022 5.64797C10.2266 6.25738 11.2506 7.17173 11.992 8.29997L11.175 8.87497C9.678 6.74597 8.393 6.29197 7 6.29197C5.67392 6.29197 4.40215 6.81875 3.46447 7.75644C2.52678 8.69412 2 9.96589 2 11.292C2 12.6181 2.52678 13.8898 3.46447 14.8275C4.40215 15.7652 5.67392 16.292 7 16.292H15C16.8565 16.292 18.637 15.5545 19.9497 14.2417C21.2625 12.929 22 11.1485 22 9.29197C22 7.43545 21.2625 5.65498 19.9497 4.34222C18.637 3.02947 16.8565 2.29197 15 2.29197Z"
              fill="currentColor"
            />
          </>
        );
      }
      case "cloudy": {
        return (
          <>
            <path
              d="M7.051 2.50299H9.051V4.50299H7.051V2.50299ZM2.38 5.22399L3.794 3.80999L5.207 5.22499L3.792 6.63899L2.38 5.22399ZM1 8.50299H3V10.503H1V8.50299ZM4 9.49999C4.00044 9.8866 4.05772 10.271 4.17 10.641C5.30803 9.89518 6.63936 9.49856 8 9.49999C8.17 9.49999 8.33 9.50999 8.5 9.51899C9.17669 8.4964 10.0545 7.62231 11.08 6.94999C10.555 6.31643 9.84743 5.86019 9.05369 5.64344C8.25995 5.4267 7.41868 5.46 6.64456 5.73882C5.87044 6.01763 5.20113 6.52839 4.72788 7.20147C4.25462 7.87455 4.00045 8.67719 4 9.49999ZM10.863 5.29499L12.279 3.88099L13.693 5.29599L12.277 6.70999L10.863 5.29499ZM16 7.49999C14.7986 7.49579 13.6166 7.80268 12.5691 8.39078C11.5215 8.97889 10.644 9.82817 10.022 10.856C11.2262 11.4643 12.2501 12.3772 12.992 13.504L12.175 14.079C10.678 11.957 9.393 11.5 8 11.5C6.67392 11.5 5.40215 12.0268 4.46447 12.9645C3.52678 13.9021 3 15.1739 3 16.5C3 17.8261 3.52678 19.0978 4.46447 20.0355C5.40215 20.9732 6.67392 21.5 8 21.5H16C17.8565 21.5 19.637 20.7625 20.9497 19.4497C22.2625 18.137 23 16.3565 23 14.5C23 12.6435 22.2625 10.863 20.9497 9.55024C19.637 8.23749 17.8565 7.49999 16 7.49999Z"
              fill="currentColor"
            />
          </>
        );
      }
    }
    return <></>;
  };

  return (
    <Layout home={true} image={client.image}>
      <Content>
        <Column>
          <CardGlass>
            <EmissionCardHeader>
              <LocalCardTitle>
                <Trans
                  i18nKey="shared.co2emission"
                  components={{sub: <sub></sub>}}
                />
              </LocalCardTitle>
              <LocalCardSubTitle>
                {t("pages.index.lastTwoWeeks")}
              </LocalCardSubTitle>
              <EmissionMore to="/dashboard">
                {t("pages.index.seeMore")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none">
                  <path
                    d="M6.47003 4L5.53003 4.94L8.58336 8L5.53003 11.06L6.47003 12L10.47 8L6.47003 4Z"
                    fill="#000099"
                  />
                </svg>
              </EmissionMore>
            </EmissionCardHeader>
            <EmmisionCardContent>
              <EmmisionChartWrapper>
                <EmissionChart data={emissionChartData} legend={false} />
              </EmmisionChartWrapper>
            </EmmisionCardContent>
          </CardGlass>

          <Row>
            <BatteryCard {...localization.battery} />
            <LocalizationInfo {...localization.info} />
          </Row>
        </Column>
        <InfoColumn>
          <Temperature>
            <TemperatureIcon
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none">
              {getIcon(localization.weather.icon)}
            </TemperatureIcon>
            {localization.weather.temperature}
          </Temperature>
          <WeatherDescription>
            {localization.weather.description}
          </WeatherDescription>
          <LocalizationName>{localization.name}</LocalizationName>
          <ConsumptionDescription>
            {t("pages.index.currentConsumption")}
          </ConsumptionDescription>
          <Consumption>
            <ConsumptionIconWrapper>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none">
                <path
                  d="M5.33341 16L7.21341 14.12L14.6667 21.56V5.33337H17.3334V21.56L24.7734 14.1067L26.6667 16L16.0001 26.6667L5.33341 16Z"
                  fill="#D2E6AE"
                />
              </svg>
            </ConsumptionIconWrapper>
            {localization.currentConsumption} {t("shared.kw")}
          </Consumption>
        </InfoColumn>
      </Content>
    </Layout>
  );
};

export default Index;
