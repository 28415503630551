const HarmonyPlaza = {
  name: "Harmony Building",
  weather: {
    description: "Partly clouded, Rain and thunder",
    temperature: "10°C",
    icon: "cloudy",
  },
  currentConsumption: 10.27,
  info: {
    door: true,
    fans: false,
    smoke: true,
    earth: true,
    grid: 38.5,
    pv: false,
  },
  battery: {
    battery: 79.2,
    amper: -0.5,
    volt: 55.0,
    temperature: 18.5,
    power: 5000,
    l1: 240,
    l2: 250,
    l3: 260,
  },
  energyConsumption: [
    {
      energy: 26500,
      consumption: 1950,
      tco2: 8700,
    },
    {
      energy: 17500,
      consumption: 1600,
      tco2: 7200,
    },
    {
      energy: 12500,
      consumption: 1200,
      tco2: 4500,
    },
    {
      energy: 17500,
      consumption: 1500,
      tco2: 6500,
    },
    {
      energy: 22000,
      consumption: 1800,
      tco2: 7500,
    },
    {
      energy: 14500,
      consumption: 1300,
      tco2: 5000,
    },
    {
      energy: 27000,
      consumption: 1900,
      tco2: 8500,
    },
    {
      energy: 19500,
      consumption: 1600,
      tco2: 7000,
    },
    {
      energy: 10500,
      consumption: 1100,
      tco2: 4000,
    },
    {
      energy: 28000,
      consumption: 1950,
      tco2: 9000,
    },
    {
      energy: 19500,
      consumption: 1600,
      tco2: 7000,
    },
    {
      energy: 14500,
      consumption: 1300,
      tco2: 5000,
    },
    {
      energy: 22000,
      consumption: 1800,
      tco2: 7500,
    },
    {
      energy: 12500,
      consumption: 1200,
      tco2: 4500,
    },
  ],
  energyConsumptionByYear: [
    [
      {
        energy: 12500,
        consumption: 1200,
        tco2: 4500,
      },
      {
        energy: 17500,
        consumption: 1500,
        tco2: 6500,
      },
      {
        energy: 22000,
        consumption: 1800,
        tco2: 7500,
      },
      {
        energy: 18500,
        consumption: 1600,
        tco2: 7200,
      },
      {
        energy: 11500,
        consumption: 1200,
        tco2: 4500,
      },
      {
        energy: 25500,
        consumption: 1900,
        tco2: 8500,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
    ],
    [
      {
        energy: 18500,
        consumption: 1600,
        tco2: 7200,
      },
      {
        energy: 11500,
        consumption: 1200,
        tco2: 4500,
      },
      {
        energy: 25500,
        consumption: 1900,
        tco2: 8500,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    [
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
    ],
  ],
  emissionActivity: {
    consumption: 450,
    tco2: 400,
    energy: 200,
    scope1: 150,
    scope2: 100,
    scope3: 50,
  },
  scope1: {
    thisMonth: 23.74,
    lastMonth: 56.53,
    emissions: [
      {
        energy: 25500,
        consumption: 1100,
        tco2: 7200,
      },
      {
        energy: 25500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 13500,
        consumption: 1450,
        tco2: 4600,
      },
      {
        energy: 19500,
        consumption: 3050,
        tco2: 5800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 9500,
        consumption: 550,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {
        name: "consumtpion",
        value: 35,
        color: {
          start: "#4DBFD8",
          end: "#008CAA",
        },
      },
      {
        name: "tco2",
        value: 65,
        color: {
          start: "#87CAB2",
          end: "#1C9576",
        },
      },
      {
        name: "energy",
        value: 25,
        color: {
          start: "#A9D06B",
          end: "#669E1D",
        },
      },
    ],
  },
  scope2: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {
        name: "consumtpion",
        value: 35,
        color: {
          start: "#4DBFD8",
          end: "#008CAA",
        },
      },
      {
        name: "tco2",
        value: 65,
        color: {
          start: "#87CAB2",
          end: "#1C9576",
        },
      },
      {
        name: "energy",
        value: 25,
        color: {
          start: "#A9D06B",
          end: "#669E1D",
        },
      },
    ],
  },
  scope3: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {
        name: "consumtpion",
        value: 35,
        color: {
          start: "#4DBFD8",
          end: "#008CAA",
        },
      },
      {
        name: "tco2",
        value: 65,
        color: {
          start: "#87CAB2",
          end: "#1C9576",
        },
      },
      {
        name: "energy",
        value: 25,
        color: {
          start: "#A9D06B",
          end: "#669E1D",
        },
      },
    ],
  },
  peaksettings: {
    dischargePoint: 1000,
    chargePoint: 800,
    maxPower: 14000,
    minPower: 16000,
    chart: [1200, 800, 700, 400, -400, -900, -1100, -300, 400, 1500],
  },
  power: {
    grid: {
      value: 40,
      max: 100,
    },
    shaper: {
      value: 60,
      max: 100,
    },
    load: {
      value: 23,
      max: 100,
    },
  },
  history: [
    {
      batteryVoltage: 46,
      batteryCapacity: 100,
      meterPower: 0,
      storagePower: 96076,
    },
    {
      batteryVoltage: 55,
      batteryCapacity: 90,
      meterPower: 0,
      storagePower: 49331,
    },
    {
      batteryVoltage: 66,
      batteryCapacity: 70,
      meterPower: 3000,
      storagePower: 83433,
    },
    {
      batteryVoltage: 80,
      batteryCapacity: 50,
      meterPower: 3000,
      storagePower: 56370,
    },
    {
      batteryVoltage: 90,
      batteryCapacity: 30,
      meterPower: 3000,
      storagePower: 34332,
    },
    {
      batteryVoltage: 71,
      batteryCapacity: 40,
      meterPower: 5000,
      storagePower: 24274,
    },
    {
      batteryVoltage: 45,
      batteryCapacity: 60,
      meterPower: 5000,
      storagePower: 76142,
    },
    {
      batteryVoltage: 65,
      batteryCapacity: 80,
      meterPower: 3000,
      storagePower: 2778,
    },
    {
      batteryVoltage: 53,
      batteryCapacity: 70,
      meterPower: 4000,
      storagePower: 67675,
    },
    {
      batteryVoltage: 50,
      batteryCapacity: 65,
      meterPower: 2000,
      storagePower: 7223,
    },
  ],
  converter: [
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: false,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
  ],
  settings: {
    general: {
      siteName: "PowerShaper",
      timezone: "Europe/Oslo",
      time: "05/03/2024 12:34",
    },
    network: {
      type: "DHCP",
      name: "Wired connection 1",
      host: "pixiief29ce",
      ip: "192.168.1.128",
      mask: "255.255.255.0",
      gateway: "192.168.1.1",
      dns1: "192.168.1.1",
      dns2: "-",
      ntp1: "0.pool.ntp.org",
      ntp2: "1.pool.ntp.org",
      ntp3: "2.pool.ntp.org",
      ntp4: "3.pool.ntp.org",
      ntpused: "2.pool.ntp.org",
    },
  },
};

export default HarmonyPlaza;
