import React from "react";
import {createBrowserRouter} from "react-router-dom";
import {
  Dashboard,
  Data,
  Index,
  Settings,
  NotFound,
  Converter,
  PeakShaving,
} from "@/pages";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Index />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/data",
    element: <Data />,
  },
  {
    path: "/converter",
    element: <Converter />,
  },
  {
    path: "/settings",
    element: <Settings />,
  },
  {
    path: "/settings/peak-shaving",
    element: <PeakShaving />,
  },
  {
    path: "/*",
    element: <NotFound />,
  },
]);

export default router;
