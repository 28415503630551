import React from "react";
import {
  BatteryWrapper,
  Column,
  Content,
  Text,
  Wrapper,
} from "./batteryCard.style";
import Battery from "../battery/battery";
import {useTranslation} from "react-i18next";

interface Props {
  battery: number;
  amper: number;
  volt: number;
  temperature: number;
}

const BatteryCard = ({
  battery,
  amper,
  volt,
  temperature,
}: Props): JSX.Element => {
  const {t} = useTranslation();

  return (
    <Wrapper>
      <Content>
        <BatteryWrapper>
          <Battery value={battery} />
        </BatteryWrapper>

        <Column>
          <Text primary={true}>{battery.toFixed(1)}%</Text>
          <Text primary={false}>
            {amper.toFixed(1)} {t("shared.amper")}
          </Text>
          <Text primary={false}>
            {volt.toFixed(1)} {t("shared.volt")}
          </Text>
          <Text primary={false}>
            {temperature.toFixed(1)}
            {t("shared.celcius")}
          </Text>
        </Column>
      </Content>
    </Wrapper>
  );
};

export default BatteryCard;
