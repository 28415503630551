import {pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

interface ButtonProps {
  color: string;
}

export const Button = styled.button<ButtonProps>`
  border-radius: 600px;
  padding: ${pxToRem(8)};
  display: flex;
  border: none;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  &:active {
    filter: brightness(70%);
  }

  box-shadow: 0px 4px 4px 0px rgba(201, 202, 202, 0.25);
`;

export const ContainedButton = styled(Button)`
  background-color: ${props => props.color};
  color: ${theme.colors.white};
  border: 1px solid ${props => props.color};
  &:disabled {
    background-color: transparent;
    border: 1px solid ${theme.colors.grey[300]};
    color: ${theme.colors.grey[300]};
    &:active {
      filter: brightness(100%);
    }
  }
`;

export const OutlinedButton = styled(Button)`
  border: 1px solid ${props => props.color};
  color: ${props => props.color};
  &:disabled {
    background-color: transparent;
    border: 1px solid ${theme.colors.grey[300]};
    color: ${theme.colors.grey[300]};
    &:active {
      filter: brightness(100%);
    }
  }
`;
