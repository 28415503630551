import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {LegendText, LegendTextCapitalize} from "./emissionChart.style";
import CustomizedTick from "./customizedTick";
import {Trans} from "react-i18next";

export interface ChartData {
  name: string;
  energy: number;
  tco2: number;
  consumption: number;
}

interface Props {
  legend: boolean;
  data: ChartData[];
}

const renderLegendText = (value: string, entry: any): JSX.Element => {
  if (entry.dataKey === "tco2") {
    return (
      <LegendText>
        <Trans i18nKey="shared.tco2" components={{sub: <sub></sub>}} />
      </LegendText>
    );
  }
  return (
    <LegendTextCapitalize>
      <Trans i18nKey={`shared.${value}`} components={{sub: <sub></sub>}} />
    </LegendTextCapitalize>
  );
};

const EmissionChart = ({legend, data}: Props): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} margin={{top: 5, left: 0, right: 0, bottom: 0}}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          tick={<CustomizedTick />}
          axisLine={false}
          tickLine={false}
          xAxisId={0}
        />
        <XAxis xAxisId={1} hide />
        <XAxis xAxisId={2} hide />
        <YAxis
          tick={<CustomizedTick />}
          axisLine={false}
          tickLine={false}
          ticks={[0, 5000, 10000, 15000, 20000, 25000, 30000]}
          interval={0}
        />
        {legend && (
          <Legend
            align="right"
            iconType="circle"
            formatter={renderLegendText}
          />
        )}
        <defs>
          <linearGradient
            id="emissionChartEnergyBarBackground"
            x1="0%"
            y1="100%"
            x2="0%"
            y2="50%"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#A9D06B" />
            <stop offset="1" stopColor="#669E1D" />
          </linearGradient>
          <linearGradient
            x1="0%"
            y1="100%"
            x2="0%"
            y2="50%"
            id="emissionChartTco2BarBackground"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#87CAB2" />
            <stop offset="1" stopColor="#1C9576" />
          </linearGradient>
          <linearGradient
            x1="0%"
            y1="100%"
            x2="0%"
            y2="50%"
            id="emissionChartConsumptionBarBackground"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#4DBFD8" />
            <stop offset="1" stopColor="#008CAA" />
          </linearGradient>
        </defs>
        <Bar
          dataKey="energy"
          xAxisId={2}
          fill="url(#emissionChartEnergyBarBackground)"
          radius={[16, 16, 16, 16]}
          barSize={20}
        />
        <Bar
          dataKey="tco2"
          xAxisId={1}
          fill="url(#emissionChartTco2BarBackground)"
          radius={[16, 16, 16, 16]}
          barSize={20}
        />
        <Bar
          dataKey="consumption"
          xAxisId={0}
          fill="url(#emissionChartConsumptionBarBackground)"
          radius={[16, 16, 16, 16]}
          barSize={20}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EmissionChart;
