import Layout from "@/components/layout/layout";
import React, {useContext} from "react";
import {
  CheckboxWrapper,
  DetailsLink,
  DetailsText,
  GeneralCard,
  LeftColumn,
  NetworkSettingsContent,
  NewtorkCard,
  ServiceCard,
  SettingsCardContent,
  SettingsCardTitle,
  SettingsItem,
  SettingsTitle,
  SettingsValue,
  Wrapper,
} from "./settings.style";

import {useTranslation} from "react-i18next";
import {ProjectContext} from "@/contexts/projectContext";
import CheckboxInput from "@/components/ui/checkboxInput/checkboxInput";
import theme from "@/styles/theme";

const Settings = (): JSX.Element => {
  const {t} = useTranslation();
  const {project} = useContext(ProjectContext);
  const {settings} = project;

  return (
    <Layout home={false} active="settings">
      <h1>{t("pages.settings.title")}</h1>
      <Wrapper>
        <LeftColumn>
          <GeneralCard>
            <SettingsCardContent>
              <SettingsCardTitle>
                {t("pages.settings.general.title")}
              </SettingsCardTitle>
              <SettingsItem>
                <SettingsTitle>
                  {t("pages.settings.general.sitename")}
                </SettingsTitle>
                <SettingsValue>{settings.general.siteName}</SettingsValue>
              </SettingsItem>
              <SettingsItem>
                <SettingsTitle>
                  {t("pages.settings.general.timezone")}
                </SettingsTitle>
                <SettingsValue>{settings.general.timezone}</SettingsValue>
              </SettingsItem>
              <SettingsItem>
                <SettingsTitle>
                  {t("pages.settings.general.systemtime")}
                </SettingsTitle>
                <SettingsValue>{settings.general.time}</SettingsValue>
              </SettingsItem>
            </SettingsCardContent>
          </GeneralCard>
          <ServiceCard>
            <SettingsCardContent>
              <SettingsCardTitle>
                {t("pages.settings.service.title")}
              </SettingsCardTitle>
              <SettingsItem>
                <SettingsTitle>
                  {t("pages.settings.service.demandresopnse")}
                </SettingsTitle>
                <DetailsText>
                  {t("pages.settings.service.details")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M6.47027 4L5.53027 4.94L8.58361 8L5.53027 11.06L6.47027 12L10.4703 8L6.47027 4Z"
                      fill="#000099"
                    />
                  </svg>
                </DetailsText>
              </SettingsItem>
              <SettingsItem>
                <SettingsTitle>
                  {t("pages.settings.service.peakshaving")}
                </SettingsTitle>
                <DetailsLink to="/settings/peak-shaving">
                  {t("pages.settings.service.details")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M6.47027 4L5.53027 4.94L8.58361 8L5.53027 11.06L6.47027 12L10.4703 8L6.47027 4Z"
                      fill="#000099"
                    />
                  </svg>
                </DetailsLink>
              </SettingsItem>
              <SettingsItem>
                <SettingsTitle>
                  {t("pages.settings.service.target")}
                </SettingsTitle>
                <DetailsText>
                  {t("pages.settings.service.details")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M6.47027 4L5.53027 4.94L8.58361 8L5.53027 11.06L6.47027 12L10.4703 8L6.47027 4Z"
                      fill="#000099"
                    />
                  </svg>
                </DetailsText>
              </SettingsItem>
              <SettingsItem>
                <SettingsTitle>
                  {t("pages.settings.service.voltagesupport")}
                </SettingsTitle>
                <DetailsText>
                  {t("pages.settings.service.details")}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none">
                    <path
                      d="M6.47027 4L5.53027 4.94L8.58361 8L5.53027 11.06L6.47027 12L10.4703 8L6.47027 4Z"
                      fill="#000099"
                    />
                  </svg>
                </DetailsText>
              </SettingsItem>
            </SettingsCardContent>
          </ServiceCard>
        </LeftColumn>

        <NewtorkCard>
          <SettingsCardContent>
            <SettingsCardTitle>
              {t("pages.settings.network.title")}
            </SettingsCardTitle>
            <NetworkSettingsContent>
              <div>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.type")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.type}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.name")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.name}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.host")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.host}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.address")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.ip}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.mask")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.mask}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.gateway")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.gateway}</SettingsValue>
                </SettingsItem>
                <CheckboxWrapper>
                  <CheckboxInput
                    color={theme.colors.lightGreen[400]}
                    defaultChecked={false}
                    label={t("pages.settings.network.checkbox")}
                  />
                </CheckboxWrapper>
              </div>
              <div>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.dns1")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.dns1}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.dns2")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.dns2}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.ntp1")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.ntp1}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.ntp2")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.ntp2}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.ntp3")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.ntp3}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.ntp4")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.ntp4}</SettingsValue>
                </SettingsItem>
                <SettingsItem>
                  <SettingsTitle>
                    {t("pages.settings.network.ntpused")}
                  </SettingsTitle>
                  <SettingsValue>{settings.network.ntpused}</SettingsValue>
                </SettingsItem>
              </div>
            </NetworkSettingsContent>
          </SettingsCardContent>
        </NewtorkCard>
      </Wrapper>
    </Layout>
  );
};

export default Settings;
