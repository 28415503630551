import React, {createContext, useEffect} from "react";
import {useTranslation} from "react-i18next";

export const LanguageContext = createContext({});

export const LanguageProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const {i18n} = useTranslation();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  useEffect(() => {
    i18n.changeLanguage(urlParams.get("lng") ?? "en").catch(e => {
      console.error(e);
    });
  }, []);

  return (
    <LanguageContext.Provider value={{}}>{children}</LanguageContext.Provider>
  );
};
