import React, {useEffect, useState} from "react";
import {
  ActivityChartWrapper,
  Column,
  EmissionChartWrapper,
  LastMonth,
  LastMonthWrapper,
  Row,
  Title,
  Value,
  ValueDescription,
  Wrapper,
} from "./scopeBox.style";
import type IEmission from "@/interfaces/IEmission";
import type {ActivityChartData} from "../charts/activityChart/activityChart";
import ActivityChart from "../charts/activityChart/activityChart";
import EmissionChart from "../charts/emissionChart/emissionChart";
import {ValueIndicator} from "../ui/valueIndicator";
import calculateDiffrenceInPercent from "@/helpers/calculateDiffrenceInPercent";
import {useTranslation, Trans} from "react-i18next";

interface Props {
  title: string;
  thisMonth: number;
  lastMonth: number;
  emissions: IEmission[];
  activity: ActivityChartData[];
  className?: string;
}

const getTextFromValue = (value: string): string => {
  return "";
};

const ScopeBox = ({
  title,
  thisMonth,
  lastMonth,
  emissions,
  activity,
  className,
}: Props): JSX.Element => {
  const [emissionDiffrence, setEmissionDiffrence] = useState(0);
  const [emissionDiffrencePercent, setEmissionDiffrencePercent] = useState(0);

  useEffect(() => {
    setEmissionDiffrence(thisMonth - lastMonth);
    setEmissionDiffrencePercent(
      calculateDiffrenceInPercent(thisMonth, lastMonth),
    );
  }, [lastMonth, thisMonth]);

  const {t} = useTranslation();

  return (
    <Wrapper className={className}>
      <Row>
        <Column>
          <Title>{title}</Title>
          <Value>
            {thisMonth}
            <ValueDescription>
              <Trans
                i18nKey="components.scopeBox.tco2"
                components={{sub: <sub></sub>}}
              />
            </ValueDescription>
          </Value>
          <LastMonthWrapper>
            <LastMonth>{t("components.scopeBox.lastMonth")}</LastMonth>
            <ValueIndicator positive={emissionDiffrencePercent > 0}>
              {emissionDiffrence.toPrecision(3)}{" "}
              <Trans i18nKey="shared.tco2" components={{sub: <sub></sub>}} />
              <sup>({emissionDiffrencePercent.toPrecision(3)}%)</sup>
            </ValueIndicator>
          </LastMonthWrapper>
        </Column>
        <ActivityChartWrapper>
          <ActivityChart
            data={activity.map((a, key) => {
              return {
                key,
                text: getTextFromValue(a.name),
                ...a,
              };
            })}
          />
        </ActivityChartWrapper>
      </Row>
      <EmissionChartWrapper>
        <EmissionChart
          legend={true}
          data={emissions.map((e, key) => {
            const month = key + 1;
            return {
              key,

              name: month.toString(),
              ...e,
            };
          })}
        />
      </EmissionChartWrapper>
    </Wrapper>
  );
};

export default ScopeBox;
