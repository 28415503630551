import styled, {css} from "styled-components";
import app_background from "@/assets/app-background.png";
import theme from "@/styles/theme";
import Container from "../ui/container";

interface WrapperProps {
  withimage: boolean;
  imageurl?: string;
}

export const Wrapper = styled.main<WrapperProps>`
  min-height: 100vh;
  background-color: ${theme.colors.grey[100]};
  ${({withimage, imageurl}) =>
    withimage &&
    css`
      background-image: linear-gradient(
          232deg,
          rgba(0, 0, 0, 0) 0,
          rgba(0, 0, 0, 0.1) 20%
        ),
        url(${imageurl ?? app_background});
      background-position: center;
      background-size: cover;
    `}
`;

export const Content = styled(Container)``;
