import styled from "styled-components";
import Card, {CardContent} from "../ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";

export const Wrapper = styled(Card)`
  min-height: ${pxToRem(240)};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ActivityChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  flex: 5;
  max-width: 275px;
`;

export const Content = styled(CardContent)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: center;
  flex: 5;
`;

export const Legend = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(12)};
  justify-content: center;
`;

export const LegendItem = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
`;

interface LegendCircleProps {
  color: string;
}

export const LegendCircle = styled.div<LegendCircleProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.color};
`;

export const LegendText = styled.span`
  font-size: ${fluidSize(8, 10)};
  color: ${theme.colors.grey[500]};
`;
