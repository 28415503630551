import Layout from "@/components/layout/layout";
import React, {useContext, useEffect, useState} from "react";
import {
  Wrapper,
  Box,
  BoxContent,
  Column,
  ChartWrapper,
  BoxFooter,
  Row,
  ButtonsWrapper,
  DefaultButton,
  SaveButton,
  GuideText,
  BackToHomeLink,
  Header,
} from "./peakShaving.style";
import NumberInput from "@/components/ui/numberInput/numberInput";
import theme from "@/styles/theme";
import CheckboxInput from "@/components/ui/checkboxInput/checkboxInput";
import {ProjectContext} from "@/contexts/projectContext";
import SettingsChart from "@/components/charts/settingsChart/settingsChart";
import type {SettingsChartData} from "@/components/charts/settingsChart/settingsChart";
import {useTranslation} from "react-i18next";

const PeakShaving = (): JSX.Element => {
  const {project} = useContext(ProjectContext);
  const {peaksettings: settings} = project;
  const [dischargePoint, setDischargePoint] = useState(settings.dischargePoint);
  const [chargePoint, setChargePoint] = useState(settings.chargePoint);
  const [minPower, setMinPower] = useState(settings.minPower);
  const [maxPower, setMaxPower] = useState(settings.maxPower);
  const [chartData, setChartData] = useState<SettingsChartData[]>([]);

  const refreshChartData = (): void => {
    const newChartData: SettingsChartData[] = settings.chart.map(value => {
      if (value > dischargePoint) {
        const belowChargePoint = 0;
        const newValue = dischargePoint;
        const aboveDischargePoint = value - dischargePoint;
        return {
          value: newValue,
          aboveDischargePoint,
          belowChargePoint,
        };
      }
      if (value < -chargePoint) {
        const aboveDischargePoint = 0;
        const newValue = -chargePoint;
        const belowChargePoint = -(-value - chargePoint);
        return {
          value: newValue,
          aboveDischargePoint,
          belowChargePoint,
        };
      }
      return {
        value,
        aboveDischargePoint: 0,
        belowChargePoint: 0,
      };
    });

    setChartData(newChartData);
  };

  useEffect(() => {
    refreshChartData();
  }, [dischargePoint, chargePoint, project]);

  useEffect(() => {
    setDefaultValue();
  }, [project]);

  const setDefaultValue = (): void => {
    const {peaksettings: settings} = project;
    setChargePoint(settings.chargePoint);
    setDischargePoint(settings.dischargePoint);
    setMinPower(settings.minPower);
    setMaxPower(settings.maxPower);
  };

  const {t} = useTranslation();

  return (
    <Layout home={false} active="settings">
      <Header>
        <BackToHomeLink to="/settings">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M14.2951 18L15.7051 16.59L11.1251 12L15.7051 7.41L14.2951 6L8.29508 12L14.2951 18Z"
              fill="#009"
            />
          </svg>
          {t("shared.back")}
        </BackToHomeLink>
        <h1>{t("pages.peaksettings.title")}</h1>
        <GuideText>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none">
            <path
              d="M7.3335 5.16659H8.66683V6.49992H7.3335V5.16659ZM7.3335 7.83325H8.66683V11.8333H7.3335V7.83325ZM8.00016 1.83325C4.32016 1.83325 1.3335 4.81992 1.3335 8.49992C1.3335 12.1799 4.32016 15.1666 8.00016 15.1666C11.6802 15.1666 14.6668 12.1799 14.6668 8.49992C14.6668 4.81992 11.6802 1.83325 8.00016 1.83325ZM8.00016 13.8333C5.06016 13.8333 2.66683 11.4399 2.66683 8.49992C2.66683 5.55992 5.06016 3.16659 8.00016 3.16659C10.9402 3.16659 13.3335 5.55992 13.3335 8.49992C13.3335 11.4399 10.9402 13.8333 8.00016 13.8333Z"
              fill="#000099"
            />
          </svg>
          {t("pages.peaksettings.guide")}
        </GuideText>
      </Header>

      <Wrapper>
        <Box>
          <BoxContent>
            <Column>
              <NumberInput
                value={dischargePoint}
                onChange={value => {
                  setDischargePoint(value);
                }}
                min={0}
                color={theme.colors.blue[400]}
                step={100}
                label={t("pages.peaksettings.dischargePoint")}
              />
              <NumberInput
                value={chargePoint}
                onChange={value => {
                  setChargePoint(value);
                }}
                min={0}
                color={theme.colors.lightGreen[400]}
                step={100}
                label={t("pages.peaksettings.chargePoint")}
              />
              <NumberInput
                value={minPower}
                onChange={value => {
                  setMinPower(value);
                }}
                min={0}
                color="#009"
                step={100}
                label={t("pages.peaksettings.maxChargeower")}
              />
              <NumberInput
                onChange={value => {
                  setMaxPower(value);
                }}
                value={maxPower}
                min={0}
                color="#009"
                step={100}
                label={t("pages.peaksettings.maxDischargePoint")}
              />
            </Column>
            <ChartWrapper>
              <SettingsChart
                data={chartData}
                chargePoint={chargePoint}
                dischargePoint={dischargePoint}
              />
            </ChartWrapper>
          </BoxContent>
          <BoxFooter>
            <Row>
              <CheckboxInput
                color={theme.colors.lightGreen[400]}
                defaultChecked={false}
                label="Keep service active when idle"
              />
              <ButtonsWrapper>
                <DefaultButton onClick={setDefaultValue} color="#009">
                  {t("pages.peaksettings.default")}
                </DefaultButton>
                <SaveButton color="#009">
                  {t("pages.peaksettings.save")}
                </SaveButton>
              </ButtonsWrapper>
            </Row>
          </BoxFooter>
        </Box>
      </Wrapper>
    </Layout>
  );
};

export default PeakShaving;
