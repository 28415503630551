import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";
import Card, {CardContent} from "../ui/card";

export const Wrapper = styled(Card)`
  min-height: ${pxToRem(240)};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const WrapperContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex: 1;
`;

export const NavRow = styled.nav`
  display: flex;
  align-items: baseline;
  flex: 1;
`;

export const HorizontalSelectWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  align-items: end;
  align-self: center;
  flex: 1;
`;

export const ByYearRow = styled.div`
  flex: 1;
  justify-content: end;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-size: ${fluidSize(14, 16)};
  font-weight: 400;
  color: ${theme.colors.grey[500]};
  margin: 0;
`;

export const SubTitle = styled.span`
  font-size: ${fluidSize(10, 12)};
  color: ${theme.colors.grey[400]};
  margin-left: ${pxToRem(16)};
`;

export const Value = styled.span`
  font-size: ${fluidSize(32, 40)};
  font-weight: 700;
  color: ${theme.colors.black};
  text-align: center;
`;

export const ValueDescription = styled.span`
  font-size: ${fluidSize(16, 20)};
  font-weight: 700;
  color: ${theme.colors.black};
  margin-left: ${pxToRem(16)};
`;

export const LastMonth = styled.span`
  font-size: ${fluidSize(8, 10)};
  color: ${theme.colors.grey[400]};
  margin-bottom: ${pxToRem(8)};
`;

export const NoDataText = styled.span`
  font-size: ${fluidSize(16, 18)};
  font-weight: 700;
  color: ${theme.colors.grey[300]};
`;
