import React, {useContext} from "react";
import {
  ActivityChartWrapper,
  Content,
  Legend,
  LegendCircle,
  LegendItem,
  LegendText,
  Wrapper,
} from "./emissionActivity.style";
import {CardHeader, CardSubTitle, CardTitle} from "../ui/card";
import ActivityChart from "../charts/activityChart/activityChart";
import {ProjectContext} from "@/contexts/projectContext";
import theme from "@/styles/theme";
import {Trans, useTranslation} from "react-i18next";

interface Props {
  className?: string;
}

const EmissionActivity = ({className}: Props): JSX.Element => {
  const {t} = useTranslation();
  const {project} = useContext(ProjectContext);
  const activityData = [
    {
      name: "consumption",
      trans: "shared.consumption",
      color: theme.colors.consumptionGradient,
      value: project.emissionActivity.consumption,
    },
    {
      name: "tco2",
      trans: "shared.tco2",
      color: theme.colors.tco2Gradient,
      value: project.emissionActivity.tco2,
    },
    {
      name: "energy",
      trans: "shared.energy",
      color: theme.colors.energyGradient,
      value: project.emissionActivity.energy,
    },
    {
      name: "scope1",
      trans: "components.emissionActivity.scope1",
      color: {
        start: "#27B28D",
        end: "#1C9576",
      },
      value: project.emissionActivity.scope1,
    },
    {
      name: "scope2",
      trans: "components.emissionActivity.scope2",
      color: {
        start: "#F08300",
        end: "#C76B00",
      },
      value: project.emissionActivity.scope2,
    },
    {
      name: "scope3",
      trans: "components.emissionActivity.scope3",
      color: {
        start: "#FFDB46",
        end: "#E5C53F",
      },
      value: project.emissionActivity.scope3,
    },
  ]
    .sort((a, b) => {
      if (a.value < b.value) {
        return 1;
      }
      if (a.value > b.value) {
        return -1;
      }
      return 0;
    })
    .map((value, key) => {
      return {
        key,
        ...value,
      };
    });

  return (
    <Wrapper className={className}>
      <CardHeader>
        <CardTitle>
          <Trans
            i18nKey="components.emissionActivity.title"
            components={{sub: <sub></sub>}}
          />
        </CardTitle>
        <CardSubTitle>{t("components.emissionActivity.activity")}</CardSubTitle>
      </CardHeader>
      <Content>
        <ActivityChartWrapper>
          <ActivityChart data={activityData} />
        </ActivityChartWrapper>
        <Legend>
          {activityData.map((data, key) => {
            return (
              <LegendItem key={key}>
                <LegendCircle color={data.color.end} />
                <LegendText>
                  <Trans
                    i18nKey={`${data.trans}`}
                    components={{sub: <sub></sub>}}
                  />
                </LegendText>
              </LegendItem>
            );
          })}
        </Legend>
      </Content>
    </Wrapper>
  );
};

export default EmissionActivity;
