const EmeraldHavenTower = {
  name: "Emerald Haven Tower",
  weather: {
    description: "Partly clouded, Raining",
    temperature: "16°C",
    icon: "sunny",
  },
  currentConsumption: 23.4,
  info: {
    door: true,
    fans: true,
    smoke: true,
    earth: true,
    grid: 0,
    pv: false,
  },
  battery: {
    battery: 30.1,
    amper: -1.5,
    volt: 85.0,
    temperature: 15.5,
    power: 5000,
    l1: 240,
    l2: 250,
    l3: 260,
  },
  energyConsumption: [
    {
      energy: 25500,
      consumption: 1900,
      tco2: 8500,
    },
    {
      energy: 16500,
      consumption: 1550,
      tco2: 6700,
    },
    {
      energy: 24500,
      consumption: 1850,
      tco2: 8200,
    },
    {
      energy: 15500,
      consumption: 1450,
      tco2: 6200,
    },
    {
      energy: 20500,
      consumption: 1700,
      tco2: 7400,
    },
    {
      energy: 28500,
      consumption: 2000,
      tco2: 9500,
    },
    {
      energy: 11500,
      consumption: 1250,
      tco2: 4700,
    },
    {
      energy: 29500,
      consumption: 2050,
      tco2: 9800,
    },
    {
      energy: 17500,
      consumption: 1650,
      tco2: 7300,
    },
    {
      energy: 14500,
      consumption: 1500,
      tco2: 6000,
    },
    {
      energy: 26500,
      consumption: 1950,
      tco2: 8700,
    },
    {
      energy: 17500,
      consumption: 1600,
      tco2: 7200,
    },
    {
      energy: 26500,
      consumption: 1950,
      tco2: 8700,
    },
    {
      energy: 17500,
      consumption: 1600,
      tco2: 7200,
    },
  ],
  energyConsumptionByYear: [
    [
      {
        energy: 19500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    [
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 16500,
        consumption: 1550,
        tco2: 6700,
      },
      {
        energy: 24500,
        consumption: 1850,
        tco2: 8200,
      },
      {
        energy: 15500,
        consumption: 1450,
        tco2: 6200,
      },
    ],
    [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
  ],
  emissionActivity: {
    consumption: 1200,
    tco2: 550,
    energy: 100,
    scope1: 70,
    scope2: 200,
    scope3: 20,
  },
  scope1: {
    thisMonth: 23.74,
    lastMonth: 56.53,
    emissions: [
      {
        energy: 25500,
        consumption: 1100,
        tco2: 7200,
      },
      {
        energy: 25500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 13500,
        consumption: 1450,
        tco2: 4600,
      },
      {
        energy: 19500,
        consumption: 3050,
        tco2: 5800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 9500,
        consumption: 550,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {
        name: "consumtpion",
        value: 35,
        color: {
          start: "#4DBFD8",
          end: "#008CAA",
        },
      },
      {
        name: "tco2",
        value: 65,
        color: {
          start: "#87CAB2",
          end: "#1C9576",
        },
      },
      {
        name: "energy",
        value: 25,
        color: {
          start: "#A9D06B",
          end: "#669E1D",
        },
      },
    ],
  },
  scope2: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {
        name: "consumtpion",
        value: 35,
        color: {
          start: "#4DBFD8",
          end: "#008CAA",
        },
      },
      {
        name: "tco2",
        value: 65,
        color: {
          start: "#87CAB2",
          end: "#1C9576",
        },
      },
      {
        name: "energy",
        value: 25,
        color: {
          start: "#A9D06B",
          end: "#669E1D",
        },
      },
    ],
  },
  scope3: {
    thisMonth: 44.74,
    lastMonth: 62.53,
    emissions: [
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
      {
        energy: 20500,
        consumption: 1700,
        tco2: 7400,
      },
      {
        energy: 28500,
        consumption: 2000,
        tco2: 9500,
      },
      {
        energy: 11500,
        consumption: 1250,
        tco2: 4700,
      },
      {
        energy: 29500,
        consumption: 2050,
        tco2: 9800,
      },
      {
        energy: 19500,
        consumption: 1650,
        tco2: 7300,
      },
      {
        energy: 14500,
        consumption: 1500,
        tco2: 6000,
      },
    ],
    activity: [
      {
        name: "consumtpion",
        value: 35,
        color: {
          start: "#4DBFD8",
          end: "#008CAA",
        },
      },
      {
        name: "tco2",
        value: 65,
        color: {
          start: "#87CAB2",
          end: "#1C9576",
        },
      },
      {
        name: "energy",
        value: 25,
        color: {
          start: "#A9D06B",
          end: "#669E1D",
        },
      },
    ],
  },
  peaksettings: {
    dischargePoint: 500,
    chargePoint: 700,
    maxPower: 9000,
    minPower: 5000,
    chart: [-300, -800, -1200, -400, 200, 400, 700, 900, 500, 400],
  },
  power: {
    grid: {
      value: 10,
      max: 100,
    },
    shaper: {
      value: 47,
      max: 100,
    },
    load: {
      value: 41,
      max: 100,
    },
  },
  history: [
    {
      batteryVoltage: 52,
      batteryCapacity: 72,
      meterPower: 1527,
      storagePower: 25971,
    },
    {
      batteryVoltage: 82,
      batteryCapacity: 27,
      meterPower: 7204,
      storagePower: 68213,
    },
    {
      batteryVoltage: 14,
      batteryCapacity: 25,
      meterPower: 7358,
      storagePower: 44736,
    },
    {
      batteryVoltage: 6,
      batteryCapacity: 90,
      meterPower: 9875,
      storagePower: 51565,
    },
    {
      batteryVoltage: 85,
      batteryCapacity: 3,
      meterPower: 5597,
      storagePower: 21461,
    },
    {
      batteryVoltage: 45,
      batteryCapacity: 22,
      meterPower: 2225,
      storagePower: 43872,
    },
    {
      batteryVoltage: 38,
      batteryCapacity: 90,
      meterPower: 6447,
      storagePower: 97135,
    },
    {
      batteryVoltage: 26,
      batteryCapacity: 9,
      meterPower: 8812,
      storagePower: 67235,
    },
    {
      batteryVoltage: 80,
      batteryCapacity: 69,
      meterPower: 4311,
      storagePower: 6265,
    },
    {
      batteryVoltage: 46,
      batteryCapacity: 30,
      meterPower: 2331,
      storagePower: 39274,
    },
    {
      batteryVoltage: 70,
      batteryCapacity: 31,
      meterPower: 3643,
      storagePower: 94177,
    },
  ],
  converter: [
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: false,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
    {
      vdc: 49.45,
      pdc: 0,
      idc: 0,
      vdcl: 359.1,
      tin: 30,
      tout: 33,
      vac: 232.5,
      pac: 0,
      iac: 0,
      sac: 3,
      freq: 50,
      copt: 2,
      status: true,
      tp1: "Battery",
      tp2: "On-grid",
      fdir: "OUT",
    },
  ],
  settings: {
    general: {
      siteName: "PowerShaper",
      timezone: "Europe/Oslo",
      time: "05/03/2024 12:34",
    },
    network: {
      type: "DHCP",
      name: "Wired connection 1",
      host: "pixiief29ce",
      ip: "192.168.1.128",
      mask: "255.255.255.0",
      gateway: "192.168.1.1",
      dns1: "192.168.1.1",
      dns2: "-",
      ntp1: "0.pool.ntp.org",
      ntp2: "1.pool.ntp.org",
      ntp3: "2.pool.ntp.org",
      ntp4: "3.pool.ntp.org",
      ntpused: "2.pool.ntp.org",
    },
  },
};

export default EmeraldHavenTower;
