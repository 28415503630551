import React from "react";
import {Text, Title, Wrapper} from "./activityChartTooltip.style";

const ActivityChartTooltip = (props: any): JSX.Element => {
  const {payload} = props;
  const name = payload.length > 0 ? payload[0].payload.text : "";
  const value = payload.length > 0 ? payload[0].payload.value : "";
  const color = payload.length > 0 ? payload[0].payload.color : "";

  return (
    <Wrapper>
      <Title color={color} dangerouslySetInnerHTML={{__html: name}} />
      <Text>{value}</Text>
    </Wrapper>
  );
};

export default ActivityChartTooltip;
