import React from "react";
import {Stack, Wrapper} from "./battery.style";

interface Props {
  value: number;
  className?: string;
  variant?: "vertical" | "horizontal";
}

const Battery = ({
  value,
  className,
  variant = "vertical",
}: Props): JSX.Element => {
  const stackSize = 5;
  return (
    <Wrapper className={className} variant={variant}>
      {Array.from({length: stackSize}).map((v, key) => {
        return (
          <Stack
            variant={variant}
            fill={(100 / stackSize) * key + 1 < value}
            key={key}
          />
        );
      })}
    </Wrapper>
  );
};

export default Battery;
