import {createGlobalStyle} from "styled-components";
import {fluidSize, pxToRem} from "./helpers";
import theme from "./theme";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: "Instrument Sans";
    font-weight: 400;
    overflow-y: scroll;
  }

  h1 {
    font-size: ${fluidSize(36, 40)};
    font-weight: 600;
    color: ${theme.colors.grey[500]};
    margin-top: ${pxToRem(32)};
    margin-bottom: ${pxToRem(32)};
  }

  h2 {
    font-size: ${fluidSize(24, 28)};
    font-weight: 400;
    color: ${theme.colors.grey[500]};
    margin: 0;
    margin-bottom: ${pxToRem(16)};
  }
`;

export default GlobalStyle;
