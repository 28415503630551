import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import PieCell from "./pieCell";
import ActivityChartTooltip from "./activityChartTooltip";
import type ITwoStepGradient from "@/interfaces/ITwoStepGradient";

export interface ActivityChartData {
  value: number;
  name: string;
  color: ITwoStepGradient | string;
}

interface Props {
  data: ActivityChartData[];
}

const ActivityChart = ({data}: Props): JSX.Element => {
  const gradientIds: string[] = [];
  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart margin={{top: 0, left: 0, right: 0, bottom: 0}}>
        {data.map((entry, index) => {
          if (
            typeof entry.color === "object" &&
            entry.color !== null &&
            "start" in entry.color &&
            "end" in entry.color
          ) {
            const gradient = entry.color;
            const id = `activityChart${Math.random().toString(36).slice(2, 15)}`;
            gradientIds.push(id);
            return (
              <>
                <defs>
                  <linearGradient
                    id={id}
                    x1="100%"
                    x2="0%"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor={gradient.start} />
                    <stop offset="1" stopColor={gradient.end} />
                  </linearGradient>
                </defs>
              </>
            );
          }
          return null;
        })}
        <Pie
          outerRadius="100%"
          innerRadius={30}
          data={data}
          dataKey="value"
          nameKey="activity"
          activeIndex={0}
          activeShape={<PieCell />}
          inactiveShape={<PieCell />}>
          {data.map((entry, index) => {
            if (
              typeof entry.color === "object" &&
              entry.color !== null &&
              "start" in entry.color &&
              "end" in entry.color
            ) {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={`url(#${gradientIds[index]})`}
                />
              );
            }

            return <Cell key={`cell-${index}`} fill={entry.color} />;
          })}
        </Pie>
        <Tooltip content={<ActivityChartTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ActivityChart;
