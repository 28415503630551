import React, {useEffect, useState} from "react";
import {
  ByYearRow,
  HorizontalSelectWrapper,
  LastMonth,
  NoDataText,
  Row,
  Value,
  ValueDescription,
  Wrapper,
  WrapperContent,
} from "./emissionByYear.style";
import {ValueIndicator} from "../ui/valueIndicator";
import HorizontalSelect from "../ui/horizontalSelect/horizontalSelect";
import {CardHeader, CardSubTitle, CardTitle} from "../ui/card";
import calculateDiffrenceInPercent from "@/helpers/calculateDiffrenceInPercent";
import {Trans, useTranslation} from "react-i18next";

export interface EmissionByYearInterface {
  year: string;
  emission: number;
}

interface Props {
  values: EmissionByYearInterface[];
  className?: string;
}

const EmissionByYear = ({values, className}: Props): JSX.Element => {
  const {t} = useTranslation();
  const [emission, setEmission] = useState(0);
  const [emissionDiffrence, setEmissionDiffrence] = useState(0);
  const [emissionDiffrencePercent, setEmissionDiffrencePercent] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(values.length - 1);

  useEffect(() => {
    changeYear(selectedIndex);
  }, [values]);

  const changeYear = (index: number): void => {
    setSelectedIndex(index);
    setEmission(values[index].emission);
    if (index <= 0) {
      setEmissionDiffrence(0);
      return;
    }
    setEmissionDiffrence(values[index].emission - values[index - 1].emission);
    setEmissionDiffrencePercent(
      calculateDiffrenceInPercent(
        values[index - 1].emission,
        values[index].emission,
      ),
    );
  };

  return (
    <Wrapper className={className}>
      <CardHeader>
        <CardTitle>
          <Trans
            i18nKey="components.emissionByYear.title"
            components={{sub: <sub></sub>}}
          />
        </CardTitle>
        <CardSubTitle>{t("components.emissionByYear.subTitle")}</CardSubTitle>
        <HorizontalSelectWrapper>
          <HorizontalSelect
            values={values.map(value => value.year)}
            onChange={(index, value) => {
              changeYear(index);
            }}
            defaultIndex={values.length - 1}
          />
        </HorizontalSelectWrapper>
      </CardHeader>
      <WrapperContent>
        <Row>
          <Value>{emission.toLocaleString()}</Value>
          <ValueDescription>
            <Trans i18nKey="shared.tco2" components={{sub: <sub></sub>}} />
          </ValueDescription>
        </Row>
        <ByYearRow>
          <LastMonth>{t("components.emissionByYear.lastYear")}</LastMonth>
          {emissionDiffrence !== 0 && (
            <ValueIndicator positive={emissionDiffrencePercent < 0}>
              {emissionDiffrence.toLocaleString()}{" "}
              <Trans i18nKey="shared.tco2" components={{sub: <sub></sub>}} />
              <sup>({emissionDiffrencePercent.toPrecision(3)}%)</sup>
            </ValueIndicator>
          )}
          {emissionDiffrence === 0 && (
            <NoDataText>{t("shared.noData")}</NoDataText>
          )}
        </ByYearRow>
      </WrapperContent>
    </Wrapper>
  );
};

export default EmissionByYear;
