import React, {useEffect, useState} from "react";
import {EmissionChartWrapper, Wrapper} from "./emissionByMonth.style";
import {CardHeader, CardSubTitle, CardTitle} from "../ui/card";
import {HorizontalSelectWrapper} from "../emissionByYear/emissionByYear.style";
import HorizontalSelect from "../ui/horizontalSelect/horizontalSelect";
import type IEmission from "@/interfaces/IEmission";
import EmissionChart from "../charts/emissionChart/emissionChart";
import {Trans, useTranslation} from "react-i18next";

export interface EmissionByMonthInterface {
  year: string;
  emission: IEmission[];
}

interface Props {
  values: EmissionByMonthInterface[];
  className?: string;
}

const EmissionByMonth = ({values, className}: Props): JSX.Element => {
  const {t} = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(values.length - 1);

  useEffect(() => {
    changeYear(selectedIndex);
  }, [values]);

  const changeYear = (index: number): void => {
    setSelectedIndex(index);
  };

  return (
    <Wrapper className={className}>
      <CardHeader>
        <CardTitle>
          <Trans
            i18nKey="components.emissionByMonth.title"
            components={{sub: <sub></sub>}}
          />
        </CardTitle>
        <CardSubTitle>{t("components.emissionByYear.subTitle")}</CardSubTitle>
        <HorizontalSelectWrapper>
          <HorizontalSelect
            values={values.map(value => value.year)}
            onChange={(index, value) => {
              changeYear(index);
            }}
            defaultIndex={values.length - 1}
          />
        </HorizontalSelectWrapper>
      </CardHeader>
      <EmissionChartWrapper>
        <EmissionChart
          legend={true}
          data={values[selectedIndex].emission.map((emission, key) => {
            const month = key + 1;
            return {
              name: month.toString(),
              ...emission,
            };
          })}
        />
      </EmissionChartWrapper>
    </Wrapper>
  );
};

export default EmissionByMonth;
