import {LinkWithQuery} from "@/components/linkWithQuery/linkWithQuery";
import Card, {CardContent, CardTitle} from "@/components/ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 0.58fr 1.2fr;
  /* grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 2fr; */
  min-height: calc(100vh - 96px - 64px);
  column-gap: ${pxToRem(16)};
  row-gap: ${pxToRem(16)};

  ${({theme}) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
`;

export const PowerChartCard = styled(Card)`
  display: flex;
  flex-direction: column;
  grid-column: span 4;
`;

export const PowerChartWrapper = styled(CardContent)`
  padding-bottom: 0;
  padding-top: 0;
  max-height: ${pxToRem(180)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: ${pxToRem(32)};
`;

export const PowerChartDetails = styled(CardContent)`
  background-color: rgb(239, 239, 239, 0.4);
  display: flex;
  padding-left: ${pxToRem(32)};
  padding-right: ${pxToRem(32)};
  padding-bottom: ${pxToRem(32)};
  flex-wrap: wrap;
  flex: 1;
`;

export const PowerChartDetailsBattery = styled(PowerChartDetails)`
  gap: ${pxToRem(60)};
`;

export const PowerChartDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.grey[500]};
  margin-right: ${pxToRem(64)};
`;

export const PowerChartDetailsItemTitle = styled.span`
  font-weight: 700;
`;

export const PowerChartDetailsItemValue = styled.h3`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: ${fluidSize(18, 20)};
  margin: 0;
  margin-top: ${pxToRem(4)};
`;

export const InfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${pxToRem(16)};
  grid-column: span 2;
`;

export const HistoryWrapper = styled(Card)`
  grid-column: span 10;
`;

export const HistoryWrapperTitle = styled(CardTitle)`
  font-weight: 600;
`;

export const HistoryChartWrapper = styled(CardContent)`
  padding-left: 0;
  flex: 1;
  height: 100%;
  max-height: 420px;
`;

export const HistoryChartLegendWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: ${pxToRem(16)};
`;

export const HistoryChartLegendItem = styled.div`
  display: flex;
  gap: ${pxToRem(8)};
  align-items: center;
`;

interface HistoryChartLegendCircleProps {
  color: string;
}

export const HistoryChartLegendCircle = styled.span<HistoryChartLegendCircleProps>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${props => props.color};
`;

export const HistoryChartLegendText = styled.span`
  font-size: ${fluidSize(12, 14)};
  color: ${theme.colors.grey[500]};
`;

export const AttributesList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  flex: 1.2;
  row-gap: ${pxToRem(6)};
`;

export const AttributeTitle = styled.span`
  color: ${theme.colors.grey[500]};
`;

export const AttributeValue = styled.span`
  color: ${theme.colors.grey[500]};
  font-weight: 700;
  text-align: right;
`;

export const BatteryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const BatteryCapacityTitle = styled.span`
  font-size: ${fluidSize(12, 14)};
  color: ${theme.colors.grey[500]};
`;

export const BatteryCapacityValue = styled.span`
  font-size: ${fluidSize(14, 16)};
  font-weight: 700;
  color: ${theme.colors.lightGreen[400]};
  text-align: right;
`;

export const BatteryComponentWrapper = styled.div`
  height: 40px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ConverterLink = styled(LinkWithQuery)`
  border: 1px solid #000099;
  color: #000099;
  display: flex;
  align-items: center;
  border-radius: 600px;
  padding: ${pxToRem(8)};
  padding-left: ${pxToRem(16)};
  padding-right: ${pxToRem(16)};
  display: flex;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  &:active {
    filter: brightness(70%);
  }
  &:hover {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.white};
  }
  box-shadow: 0px 4px 4px 0px rgba(201, 202, 202, 0.25);
  &:disabled {
    background-color: transparent;
    border: 1px solid ${theme.colors.grey[300]};
    color: ${theme.colors.grey[300]};
    &:active {
      filter: brightness(100%);
    }
  }
`;
