import Card, {CardContent} from "@/components/ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
`;

export const TopContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  column-gap: ${pxToRem(16)};
  row-gap: ${pxToRem(16)};
  margin-bottom: ${pxToRem(16)};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  /* grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 2fr; */
  column-gap: ${pxToRem(16)};
  row-gap: ${pxToRem(16)};
  ${({theme}) => theme.breakpoints.down("xl")} {
    grid-template-columns: 1fr 1fr;
  }
  ${({theme}) => theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
`;

export const ConsumptionCard = styled(Card)`
  background-color: #fbfef6;
`;

export const ConsumptionCardContent = styled(CardContent)`
  padding: ${pxToRem(32)};
`;

export const ConsumptionTitle = styled.h2`
  font-size: ${fluidSize(24, 28)};
  font-weight: 400;
  color: ${theme.colors.grey[500]};
  margin: 0;
`;

export const ConsumptionDescription = styled.div`
  display: flex;
  justify-content: center;
  font-size: ${fluidSize(36, 40)};
  font-weight: 600;
  color: ${theme.colors.lightGreen[400]};
  align-items: center;
  margin-top: ${pxToRem(27)};
`;

export const ConsumptionIconWrapper = styled.div`
  display: inline-flex;
  background-color: ${theme.colors.lightGreen[400]};
  border-radius: 50%;
  padding: ${pxToRem(8)};
  align-items: center;
  justify-content: center;
  margin-right: ${pxToRem(16)};
`;
