import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled, {css} from "styled-components";
import {LinkWithQuery} from "../linkWithQuery/linkWithQuery";
import Container from "../ui/container";

interface VariantProps {
  variant: "grey" | "glass";
  transparent?: boolean;
}

export const Wrapper = styled.nav<VariantProps>`
  z-index: 100;
  height: ${pxToRem(96)};
  width: inherit;
  position: sticky;
  transition: all 0.3s;
  ${({theme}) => theme.breakpoints.up("md")} {
    top: 0;
  }
  ${({theme}) => theme.breakpoints.down("md")} {
    bottom: 0;
  }
  top: 0;
  color: ${props =>
    props.variant === "glass" ? theme.colors.white : theme.colors.grey[300]};
  flex-wrap: wrap;
  border-radius: ${pxToRem(16)};
  ${({variant}) =>
    variant === "glass" &&
    css`
      border-radius: 0px 0px 8px 8px;
      background-color: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(800px);
    `}
  ${({variant, transparent}) =>
    variant === "grey" &&
    css`
      background-color: ${transparent === true
        ? `rgba(255, 255, 255, 0.25)`
        : theme.colors.grey[100]};
    `}
`;

export const Content = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 100%;
  padding-bottom: 0;
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  position: relative;
  height: 100%;
  flex: 2;
  max-width: 580px;
  min-width: 0;
`;

export const MenuItemsWrapperBorder = styled.div<VariantProps>`
  position: absolute;
  left: ${pxToRem(21)};
  right: ${pxToRem(21)};
  bottom: 2px;
  bottom: 0;
  background-color: ${props =>
    props.variant === "glass" ? theme.colors.white : theme.colors.black};
  height: 5px;
  border-radius: 100px;
`;

export const MenuText = styled.span`
  ${({theme}) => theme.breakpoints.down("lg")} {
    display: none;
  }
`;

export const SystemCheckedText = styled.span`
  ${({theme}) => theme.breakpoints.down("lg")} {
    display: none;
  }
`;

interface MenuItemProps {
  active: boolean;
}

export const MenuItem = styled(LinkWithQuery)<MenuItemProps>`
  color: inherit;
  font-weight: 500;
  font-size: ${fluidSize(12, 16)};
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: ${theme.colors.white};
    color: ${theme.colors.main};
  }
  background-color: ${props =>
    props.active ? theme.colors.white : "transparent"};
  height: ${pxToRem(24)};
  border-radius: ${pxToRem(24)};
  padding: 8px 16px 8px 16px;
  align-self: center;
  color: ${props => (props.active ? "#009" : "inherit")};
`;

export const MenuIcon = styled.svg`
  margin-right: ${pxToRem(8)};
`;

export const SystemStatusDescription = styled.span`
  color: ${theme.colors.lightGreen[400]};
  font-weight: 700;
  font-size: ${fluidSize(12, 16)};
  height: 32px;
  display: inline-flex;
  align-items: center;
  flex: 1;
  min-width: 0;
`;

export const SystemStatusIconWrapper = styled.span`
  display: inline-flex;
  background-color: ${theme.colors.lightGreen[400]};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: ${pxToRem(4)};
  margin-right: ${pxToRem(16)};
`;

export const LocalizationDropdownWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
`;

export const LocalizationDropdownContent = styled.div`
  width: ${pxToRem(250)};
`;

export const BackToHomeLink = styled(LinkWithQuery)`
  color: #009;
  font-size: ${fluidSize(12, 14)};
  font-weight: 400;
  display: flex;
  gap: ${pxToRem(8)};
  align-items: center;
  text-decoration: none;
  flex: 1;
`;
