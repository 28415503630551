import FadeIn from "@/components/ui/fadeIn/fadeIn";
import localizationsData, {
  type Client,
  defaultClient,
} from "@/localizationsData";
import type HarmonyPlaza from "@/localizationsData/harmonyplaza";
import React, {createContext, useEffect, useState} from "react";
import styled from "styled-components";

const FadeInWrapper = styled.div``;

export interface ProjectContextType {
  projects: Array<typeof HarmonyPlaza>;
  project: typeof HarmonyPlaza;
  client: Client;
  setProject: (project: typeof HarmonyPlaza) => void;
}

const ProjectContextInstance = {
  projects: localizationsData,
  project: localizationsData[0],
  client: defaultClient,
};

export const ProjectContext = createContext<ProjectContextType>(
  ProjectContextInstance as ProjectContextType,
);

export const ProjectProvider: React.FC<{children: React.ReactNode}> = ({
  children,
}) => {
  const [loading, setLoading] = useState(false);

  const [client, setClient] = useState<Client>(defaultClient);
  const [projects, setProjects] = useState<Array<typeof HarmonyPlaza>>(
    client.projects,
  );
  const [project, setProject] = useState<typeof HarmonyPlaza>(
    defaultClient.projects[0],
  );

  console.log(JSON.stringify(defaultClient.projects[0]));

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const json = urlParams.get("json");
    if (json !== null) {
      setLoading(true);
      fetch(json)
        .then(async response => {
          return await response.json();
        })
        .then(result => {
          const client = result as Client;
          setClient(client);
          setProjects(client.projects);
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <ProjectContext.Provider value={{projects, client, project, setProject}}>
      {!loading && <FadeIn wrapperTag={FadeInWrapper}>{children}</FadeIn>}
    </ProjectContext.Provider>
  );
};
