import styled from "styled-components";
import Card, {CardContent} from "../ui/card";
import {pxToRem} from "@/styles/helpers";

export const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${pxToRem(240)};
  height: 100%;
`;

export const EmissionChartWrapper = styled(CardContent)`
  padding-left: 0;
  padding-top: ${pxToRem(16)};
  flex: 5;
  min-height: 230px;
  max-height: 330px;
`;
