import styled from "styled-components";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";

const Card = styled.div`
  background: ${theme.colors.white};

  border-radius: ${pxToRem(8)};
`;

export const CardGlass = styled.div`
  border: 1px solid rgba(201, 202, 202, 0.32);
  background: linear-gradient(
    180deg,
    rgba(201, 202, 202, 0.15) 0%,
    rgba(255, 255, 255, 0.15) 100%
  );
  border-radius: ${pxToRem(8)};
`;

export const CardContent = styled.div`
  padding: ${pxToRem(24)};
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: baseline;
  padding: ${pxToRem(24)};
`;

export const CardHeaderContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CardTitle = styled.h2`
  margin-bottom: 0;
`;

export const CardSubTitle = styled.span`
  font-size: ${fluidSize(14, 16)};
  color: ${theme.colors.black};
  font-weight: 700;
  margin-left: ${pxToRem(8)};
`;

export default Card;
