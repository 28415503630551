import {LinkWithQuery} from "@/components/linkWithQuery/linkWithQuery";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const BackToHomeLink = styled(LinkWithQuery)`
  color: #009;
  font-size: ${fluidSize(12, 14)};
  font-weight: 400;
  display: flex;
  gap: ${pxToRem(8)};
  align-items: center;
  text-decoration: none;
  margin-right: ${pxToRem(28)};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: ${pxToRem(16)};
  ${theme.breakpoints.down("xl")} {
    grid-template-columns: 1fr 1fr;
  }
  ${theme.breakpoints.down("md")} {
    grid-template-columns: 1fr;
  }
`;
