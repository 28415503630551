import React from "react";
import {
  AttributeTitle,
  AttributeValue,
  BatteryWrapper,
  Column,
  Content,
  Row,
  Title,
  Wrapper,
} from "./batteryCardHorizontal.style";
import Battery from "../battery/battery";
import {useTranslation} from "react-i18next";

interface Props {
  battery: number;
  amper: number;
  volt: number;
  temperature: number;
}

const BatteryCardHorizontal = ({
  battery,
  amper,
  volt,
  temperature,
}: Props): JSX.Element => {
  const {t} = useTranslation();

  return (
    <Wrapper>
      <Content>
        <Title>{t("shared.battery")}</Title>
        <Row>
          <Column>
            <AttributeTitle>
              {t("components.batteryHorizontalCard.batteryLevel")}
            </AttributeTitle>
            <AttributeValue primary={true}>
              {battery.toFixed(1)}%
            </AttributeValue>
            <AttributeTitle>{t("components.sources.current")}</AttributeTitle>
            <AttributeValue primary={false}>
              {amper.toFixed(1)} {t("shared.amper")}
            </AttributeValue>
            <AttributeTitle>
              {t("components.batteryHorizontalCard.voltage")}
            </AttributeTitle>
            <AttributeValue primary={false}>
              {volt.toFixed(1)} {t("shared.volt")}
            </AttributeValue>
            <AttributeTitle>{t("shared.temp")}</AttributeTitle>
            <AttributeValue primary={false}>
              {temperature.toFixed(1)}
              {t("shared.celcius")}
            </AttributeValue>
          </Column>
          <BatteryWrapper>
            <Battery value={battery} />
          </BatteryWrapper>
        </Row>
      </Content>
    </Wrapper>
  );
};

export default BatteryCardHorizontal;
