import React, {useContext} from "react";
import Layout from "@/components/layout/layout";
import {useTranslation} from "react-i18next";
import {BackToHomeLink, Content, Header} from "./converter.style";
import {ProjectContext} from "@/contexts/projectContext";
import ConverterBox from "@/components/converterBox/converterBox";

const Converter = (): JSX.Element => {
  const {t} = useTranslation();
  const {project} = useContext(ProjectContext);

  return (
    <Layout home={false}>
      <Header>
        <BackToHomeLink to="/data">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M14.2951 18L15.7051 16.59L11.1251 12L15.7051 7.41L14.2951 6L8.29508 12L14.2951 18Z"
              fill="#009"
            />
          </svg>
          {t("shared.back")}
        </BackToHomeLink>
        <h1>{t("pages.converter.title")}</h1>
      </Header>
      <Content>
        {project.converter.map((data, index) => {
          return <ConverterBox {...data} key={index} />;
        })}
      </Content>
    </Layout>
  );
};

export default Converter;
