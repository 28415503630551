import styled from "styled-components";
import Card, {CardContent} from "../ui/card";
import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";

export const Wrapper = styled(Card)``;

export const Content = styled(CardContent)`
  gap: ${pxToRem(16)};
  padding: ${pxToRem(32)};
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: ${pxToRem(70)};
  align-items: center;
`;

export const Title = styled.h2`
  margin: 0;
`;

export const Column = styled.div`
  display: grid;
  gap: ${pxToRem(8)};
  grid-template-columns: 1fr 1fr;
`;

interface TextProps {
  primary: boolean;
}

export const AttributeTitle = styled.span`
  font-size: ${fluidSize(12, 14)};
  font-weight: 400;
  color: ${theme.colors.grey[500]};
`;

export const AttributeValue = styled.span<TextProps>`
  font-size: ${fluidSize(12, 14)};
  font-weight: 400;
  color: ${props =>
    props.primary ? theme.colors.lightGreen[400] : theme.colors.grey[500]};
  font-weight: 600;
  text-align: right;
`;

export const BatteryWrapper = styled.div`
  max-width: 154px;
  width: 100%;
`;
