import React from "react";
import Card from "../ui/card";
import {
  AttributesList,
  Content,
  InfoDescription,
  InfoValue,
  InfoValueIcon,
  InfoWrapper,
  Title,
} from "./cabinetStatus.style";
import {useTranslation} from "react-i18next";

interface GabinetStatusProps {
  door: boolean;
  fans: boolean;
  smoke: boolean;
  earth: boolean;
  grid: number;
  pv: boolean;
}

const CabinetStatus = ({
  door,
  fans,
  smoke,
  earth,
  grid,
  pv,
}: GabinetStatusProps): JSX.Element => {
  const {t} = useTranslation();
  return (
    <Card>
      <Content>
        <Title>{t("components.cabinetStatus.title")}</Title>
        <AttributesList>
          <InfoWrapper>
            <InfoDescription>
              {t("components.cabinetStatus.door")}
            </InfoDescription>
            <InfoValue positive={door}>
              <InfoValueIcon positive={door} />
              {door ? t("shared.closed") : t("shared.open")}
            </InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoDescription>
              {t("components.cabinetStatus.fans")}
            </InfoDescription>
            <InfoValue positive={fans}>
              <InfoValueIcon positive={fans} />
              {fans ? t("shared.working") : t("shared.notWorking")}
            </InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoDescription>
              {t("components.cabinetStatus.smokeDet")}
            </InfoDescription>
            <InfoValue positive={smoke}>
              <InfoValueIcon positive={smoke} />
              {smoke ? t("shared.working") : t("shared.notWorking")}
            </InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoDescription>
              {t("components.cabinetStatus.pv")}
            </InfoDescription>
            <InfoValue positive={pv}>
              <InfoValueIcon positive={pv} />
              {pv ? t("shared.working") : t("shared.notWorking")}
            </InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoDescription>
              {t("components.cabinetStatus.earthFault")}
            </InfoDescription>
            <InfoValue positive={earth}>
              <InfoValueIcon positive={earth} />
              {earth ? t("shared.working") : t("shared.notWorking")}
            </InfoValue>
          </InfoWrapper>
          <InfoWrapper>
            <InfoDescription>
              {t("components.cabinetStatus.grid")}
            </InfoDescription>
            <InfoValue positive={grid > 0}>
              <InfoValueIcon positive={grid > 0} />
              {grid} {t("shared.kw")}
            </InfoValue>
          </InfoWrapper>
        </AttributesList>
      </Content>
    </Card>
  );
};

export default CabinetStatus;
