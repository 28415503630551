import React from "react";
import Menu from "../menu/menu";
import {Content, Wrapper} from "./layout.style";
import {useTheme} from "styled-components";
import {useMediaQuery} from "styled-breakpoints/use-media-query";

interface Props {
  home?: boolean;
  children: JSX.Element | JSX.Element[];
  active?: "dashboard" | "data" | "settings";
  image?: string;
}

const Layout = ({
  home = false,
  children,
  active,
  image,
}: Props): JSX.Element => {
  const {breakpoints} = useTheme();
  const upMd = useMediaQuery(breakpoints.up("md") as string);
  return (
    <Wrapper withimage={home} imageurl={image}>
      {upMd && (
        <Menu
          active={active}
          variant={home ? "glass" : "grey"}
          goBack={!home}
        />
      )}
      <Content>{children}</Content>
      {!upMd && (
        <Menu
          active={active}
          variant={home ? "glass" : "grey"}
          goBack={!home}
        />
      )}
    </Wrapper>
  );
};

export default Layout;
