import {fluidSize, pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled from "styled-components";

export const Wrapper = styled.div``;

export const Label = styled.label`
  color: ${theme.colors.grey[500]};
  font-size: ${fluidSize(24, 28)};
  font-weight: 400;
  display: block;
  margin-bottom: ${pxToRem(32)};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pxToRem(8)};
  margin-top: ${pxToRem(16)};
  align-items: center;
`;

interface InputProps {
  color: string;
}

export const Input = styled.input<InputProps>`
  padding: 16px 16px 16px 16px;
  border-radius: 8px;
  border: 1px solid ${props => props.color};
  text-align: center;
  width: ${pxToRem(100 - 32)};
  background-color: transparent;
  color: ${theme.colors.black};
  &:disabled {
    color: ${theme.colors.black};
    background-color: transparent;
  }
  margin-left: ${pxToRem(8)};
  margin-right: ${pxToRem(8)};
`;

export const ButtonText = styled.span`
  font-size: ${fluidSize(10, 12)};
  font-weight: 700;
  padding-left: ${pxToRem(6)};
  padding-right: ${pxToRem(6)};
`;
