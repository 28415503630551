import theme from "@/styles/theme";
import React from "react";
import {
  BarChart,
  Bar,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import ReferenceLineLabel from "./referenceLineLabel";

export interface SettingsChartData {
  value: number;
  aboveDischargePoint: number;
  belowChargePoint: number;
}

interface Props {
  data: SettingsChartData[];
  chargePoint: number;
  dischargePoint: number;
}

const SettingsChart = ({
  data,
  chargePoint,
  dischargePoint,
}: Props): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        barGap={0}
        barCategoryGap={0}
        data={data}
        margin={{top: 15, left: 15, right: 32, bottom: 15}}>
        <CartesianGrid vertical={false} />
        <YAxis
          yAxisId={0}
          type="number"
          domain={["dataMin - 100", "dataMax + 100"]}
          axisLine={false}
          tickLine={false}
          interval={0}
        />

        <Bar
          animationBegin={200}
          dataKey="value"
          stackId={1}
          fill={theme.colors.grey[200]}
        />
        <defs>
          <linearGradient
            x1="0%"
            y1="100%"
            x2="0%"
            y2="50%"
            gradientUnits="userSpaceOnUse"
            id="aboveChargePointGradient">
            <stop offset="50%" stopColor="#206AA4" />
            <stop offset="100%" stopColor="#2980C4" />
          </linearGradient>
        </defs>
        <Bar
          animationBegin={200}
          stackId={1}
          dataKey="aboveDischargePoint"
          fill={theme.colors.blue[400]}
        />
        <defs>
          <linearGradient
            x1="0%"
            y1="100%"
            x2="0%"
            y2="50%"
            gradientUnits="userSpaceOnUse"
            id="belowChargePointGradient">
            <stop offset="0%" stopColor="#669E1D" />
            <stop offset="100%" stopColor="#7CBD27" />
          </linearGradient>
        </defs>
        <Bar
          animationBegin={200}
          stackId={1}
          dataKey="belowChargePoint"
          fill="url(#belowChargePointGradient)"
        />
        <ReferenceLine
          y={dischargePoint}
          isFront={true}
          ifOverflow="visible"
          stroke={theme.colors.blue[400]}
          strokeDasharray="2 2"
          label={<ReferenceLineLabel stroke={theme.colors.blue[400]} />}
        />
        <ReferenceLine
          y={-chargePoint}
          isFront={true}
          ifOverflow="visible"
          stroke={theme.colors.lightGreen[400]}
          strokeDasharray="2 2"
          label={<ReferenceLineLabel stroke={theme.colors.lightGreen[400]} />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SettingsChart;
