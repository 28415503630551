import {pxToRem} from "@/styles/helpers";
import theme from "@/styles/theme";
import styled, {css} from "styled-components";

interface VariantProps {
  variant: "vertical" | "horizontal";
}

export const Wrapper = styled.div<VariantProps>`
  display: flex;
  flex-direction: ${props =>
    props.variant === "vertical" ? "column-reverse" : "row"};
  gap: ${pxToRem(6)};
  height: 100%;
`;

interface StackProps {
  fill: boolean;
}

export const Stack = styled.div<StackProps & VariantProps>`
  ${({variant}) =>
    variant === "vertical" &&
    css`
      width: 100%;
      min-height: 16px;
      &:first-child {
        border-radius: 0 0 8px 8px;
      }
      &:last-child {
        border-radius: 8px 8px 0 0;
      }
    `}
  ${({variant}) =>
    variant === "horizontal" &&
    css`
      min-width: 10px;
      height: 100%;
      &:first-child {
        border-radius: 8px 0 0 8px;
      }
      &:last-child {
        border-radius: 0 8px 8px 0;
      }
    `}
  border: 1px solid ${theme.colors.lightGreen[400]};
  flex: 1;

  transition: all 0.3s;
  background-color: ${props =>
    props.fill ? theme.colors.lightGreen[400] : "transparent"};
`;
