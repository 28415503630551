import Layout from "@/components/layout/layout";
import React, {useContext} from "react";
import {
  AttributeTitle,
  AttributeValue,
  AttributesList,
  BatteryCapacityTitle,
  BatteryCapacityValue,
  BatteryComponentWrapper,
  BatteryWrapper,
  ConverterLink,
  Header,
  HistoryChartLegendCircle,
  HistoryChartLegendItem,
  HistoryChartLegendText,
  HistoryChartLegendWrapper,
  HistoryChartWrapper,
  HistoryWrapper,
  HistoryWrapperTitle,
  InfoWrapper,
  PowerChartCard,
  PowerChartDetails,
  PowerChartDetailsBattery,
  PowerChartDetailsItem,
  PowerChartDetailsItemTitle,
  PowerChartDetailsItemValue,
  PowerChartWrapper,
  Wrapper,
} from "./data.style";
import {CardHeader} from "@/components/ui/card";
import {ProjectContext} from "@/contexts/projectContext";
import GabinetStatus from "@/components/cabinetStatus/cabinetStatus";
import HistoryChart from "@/components/charts/historyChart/historyChart";
import theme from "@/styles/theme";
import PowerChartWithLabel from "@/components/charts/powerChart/powerChartWithLabel";
import {useTranslation} from "react-i18next";
import Battery from "@/components/battery/battery";

const Data = (): JSX.Element => {
  const {project: localization} = useContext(ProjectContext);
  const {t} = useTranslation();

  return (
    <Layout home={false} active="data">
      <Header>
        <h1>{t("pages.data.title")}</h1>
        <ConverterLink to="/converter">
          {t("pages.converter.title")}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none">
            <path
              d="M8.29492 16.59L12.8749 12L8.29492 7.41L9.70492 6L15.7049 12L9.70492 18L8.29492 16.59Z"
              fill="#000099"
            />
          </svg>
        </ConverterLink>
      </Header>
      <Wrapper>
        <PowerChartCard>
          <PowerChartWrapper>
            <h2>{t("pages.data.gridPower")}</h2>
            <PowerChartWithLabel index="1" data={localization.power.grid} />
          </PowerChartWrapper>
          <PowerChartDetails>
            <PowerChartDetailsItem>
              <PowerChartDetailsItemTitle>
                {t("components.sources.l1")}
              </PowerChartDetailsItemTitle>
              <PowerChartDetailsItemValue>
                {localization.battery.l1} {t("shared.volt")}
              </PowerChartDetailsItemValue>
            </PowerChartDetailsItem>
            <PowerChartDetailsItem>
              <PowerChartDetailsItemTitle>
                {t("components.sources.l2")}
              </PowerChartDetailsItemTitle>
              <PowerChartDetailsItemValue>
                {localization.battery.l2} {t("shared.volt")}
              </PowerChartDetailsItemValue>
            </PowerChartDetailsItem>
            <PowerChartDetailsItem>
              <PowerChartDetailsItemTitle>
                {t("components.sources.l3")}
              </PowerChartDetailsItemTitle>
              <PowerChartDetailsItemValue>
                {localization.battery.l3} {t("shared.volt")}
              </PowerChartDetailsItemValue>
            </PowerChartDetailsItem>
          </PowerChartDetails>
        </PowerChartCard>
        <PowerChartCard>
          <PowerChartWrapper>
            <h2>{t("pages.data.powerShaper")}</h2>
            <PowerChartWithLabel index="2" data={localization.power.shaper} />
          </PowerChartWrapper>
          <PowerChartDetailsBattery>
            <AttributesList>
              <AttributeTitle>{t("components.sources.current")}</AttributeTitle>
              <AttributeValue>
                {localization.battery.amper} {t("shared.amper")}
              </AttributeValue>
              <AttributeTitle>{t("shared.voltage")}</AttributeTitle>
              <AttributeValue>
                {localization.battery.amper} {t("shared.volt")}
              </AttributeValue>
              <AttributeTitle>{t("components.sources.temp")}</AttributeTitle>
              <AttributeValue>
                {localization.battery.temperature} {t("shared.celcius")}
              </AttributeValue>
            </AttributesList>
            <BatteryWrapper>
              <AttributesList>
                <BatteryCapacityTitle>
                  {t("components.sources.battery")}:
                </BatteryCapacityTitle>
                <BatteryCapacityValue>
                  {localization.battery.battery} %
                </BatteryCapacityValue>
              </AttributesList>
              <BatteryComponentWrapper>
                <Battery
                  variant="horizontal"
                  value={localization.battery.battery}
                />
              </BatteryComponentWrapper>
            </BatteryWrapper>
          </PowerChartDetailsBattery>
        </PowerChartCard>
        <PowerChartCard>
          <PowerChartWrapper>
            <h2>{t("pages.data.loadPower")}</h2>
            <PowerChartWithLabel index="3" data={localization.power.load} />
          </PowerChartWrapper>
          <PowerChartDetails>
            <PowerChartDetailsItem>
              <PowerChartDetailsItemTitle>
                {t("components.sources.p1")}
              </PowerChartDetailsItemTitle>
              <PowerChartDetailsItemValue>
                {localization.battery.power} {t("shared.watt")}
              </PowerChartDetailsItemValue>
            </PowerChartDetailsItem>
          </PowerChartDetails>
        </PowerChartCard>
        <InfoWrapper>
          <GabinetStatus {...localization.info} />
        </InfoWrapper>
        <HistoryWrapper>
          <CardHeader>
            <HistoryWrapperTitle>{t("pages.data.history")}</HistoryWrapperTitle>
            <HistoryChartLegendWrapper>
              <HistoryChartLegendItem>
                <HistoryChartLegendCircle color={theme.colors.lightBlue[300]} />
                <HistoryChartLegendText>
                  {t("pages.data.batteryVoltage")}
                </HistoryChartLegendText>
              </HistoryChartLegendItem>
              <HistoryChartLegendItem>
                <HistoryChartLegendCircle
                  color={theme.colors.lightGreen[300]}
                />
                <HistoryChartLegendText>
                  {t("pages.data.batteryCapacity")}
                </HistoryChartLegendText>
              </HistoryChartLegendItem>
              <HistoryChartLegendItem>
                <HistoryChartLegendCircle color={theme.colors.green[300]} />
                <HistoryChartLegendText>
                  {t("pages.data.meterPower")}
                </HistoryChartLegendText>
              </HistoryChartLegendItem>
              <HistoryChartLegendItem>
                <HistoryChartLegendCircle color={theme.colors.orange[300]} />
                <HistoryChartLegendText>
                  {t("pages.data.storagePower")}
                </HistoryChartLegendText>
              </HistoryChartLegendItem>
            </HistoryChartLegendWrapper>
          </CardHeader>
          <HistoryChartWrapper>
            <HistoryChart
              data={localization.history.map((data, key) => {
                const time = new Date(
                  Date.now() -
                    300000 * localization.history.length +
                    300000 * key,
                );
                return {
                  time:
                    key === localization.history.length - 1
                      ? t("pages.data.now")
                      : `${time.getHours()}:${time.getMinutes()}`,
                  ...data,
                };
              })}
            />
          </HistoryChartWrapper>
        </HistoryWrapper>
      </Wrapper>
    </Layout>
  );
};

export default Data;
